import React from "react"
import { Form, Row, Col } from "antd";
import { Link } from "react-router-dom"

import { InputText, Button, Select } from 'views/components/atoms'

import logo from 'assets/images/logo.svg';
import cover from 'assets/images/cover.jpg';
import {connect} from "react-redux";
import {signupRequest} from "store/ducks/Auth";

class SignUp extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.signup(values);
      }
    });
  };

  numberValidator = (rule, value, callback) => {
    if (value && Number.isInteger((value % 1)) && value.length === 10) {
      callback();
    } else {
      callback('Error!');
    }
  };

  render() {

    const jobs = [
      'Elections',
      'Enfance',
      'Population',
      'Social',
      'EServices',
      'Informatique'
    ]

    const {getFieldDecorator} = this.props.form;
    return (

      <Row className="arpec-auth-container-register">
        <Col span={12}>

          <div className="gx-text-center gx-mt-4">
            <Link className="arpec-auth-logo" to="/">
              <img  alt="Logo Arpège" src={logo}/>
            </Link> 
          </div>

          <Col span={12} className="arpec-auth-form">

            <Row>
              <Col span={24}>
                <h1 class="arpec-h1">Bienvenue !</h1>
                <p class="gx-text-muted">Merci d’entrer quelques informations pour nous permettre de créer votre Espace Client personnalisé.</p>
              </Col>

              <Col className="gx-mt-4" span={24}>
                
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item label="Entité" className="gw-w-100">
                  {
                    getFieldDecorator('entity', {
                      rules: [
                        {
                          required: true,
                          message: "Merci de saisir votre entité",
                        }
                      ],
                    })(<InputText data-cy="entity-input" placeholder="Mairie de Dienville" />)
                  }
                </Form.Item>
                <Form.Item label="Nom" className="gw-w-100">
                  {
                    getFieldDecorator('name', {
                      rules: [
                        {
                          required: true,
                          message: "Merci de saisir votre nom de famille",
                        }
                      ],
                    })(<InputText data-cy="name-input" placeholder="Durant" />)
                  }
                </Form.Item>
                <Form.Item label="Prénom" className="gw-w-100">
                  {
                    getFieldDecorator('firstname', {
                      rules: [
                        {
                          required: true,
                          message: "Merci de saisir votre nom de prénom",
                        }
                      ],
                    })(<InputText data-cy="firstname-input" placeholder="Gilbert" />)
                  }
                </Form.Item>
                <Form.Item label="Fonction" className="gw-w-100">
                  {
                    getFieldDecorator('position', {
                      rules: [
                        {
                          required: true,
                          message: "Merci de saisir votre intitulé de métier",
                        }
                      ],
                    })(<InputText data-cy="job-input" placeholder="Directeur des systèmes d'information" />)
                  }
                </Form.Item>
                <Form.Item label="Métier" hasFeedback>
                  {getFieldDecorator('job', {
                    rules: [
                      {
                        required: false,
                      },
                    ]
                  })(
                    <Select options={jobs.map((job) => ({ value: job, label: job }))} className="gx-w-100 arpec-select-multiple" data-cy="job-selector" mode="multiple" />
                  )}
                </Form.Item>
                <Form.Item label="Email" className="gw-w-100">
                  {
                    getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: 'L\'adresse mail semble invalide',
                        },
                        {
                          required: true,
                          message: "Merci de saisir votre adresse email",
                        }
                      ],
                    })(<InputText data-cy="email-input" placeholder="contact@dienville.fr" />)
                  }
                </Form.Item>
                <Form.Item label="Téléphone" className="gw-w-100">
                  {
                    getFieldDecorator('phonenumber', {
                      rules: [
                        {
                          validator: this.numberValidator,
                          required: true,
                          message: 'Votre numéro de téléphone semble invalide, veuillez respecter le format "0123456789"'
                        }
                      ]
                   })(<InputText data-cy="phonenumber-input" placeholder="0123456789" />)
                  }
                </Form.Item>
                <Form.Item className="gx-text-center">
                  <Button data-cy={"form-submit"} type="primary" htmlType="submit" className="gx-w-100">
                    Valider ma demande de compte
                  </Button>
                  <p className="gx-text-muted">Déjà un compte ? <Link to="/signin">Connectez-vous</Link></p>
                </Form.Item>
              </Form>

              </Col>
            </Row> 
            
          </Col>
          
        </Col>
        <Col className="arpec-auth-cover">
            <img src={cover} alt="présentation" />
        </Col>
      </Row>

      
    )
  }

}

const mapStateToProps = (state, props) => ({
  loading: state.auth.loading,
})

const mapDispatchToProps = dispatch => ({
  signup: (payload) => dispatch(signupRequest(payload))
})

const WrappedSignUp = Form.create({ name: 'signUp' })(SignUp);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedSignUp);