

const allowedAction = (type) =>
  type.includes('auth') ||
  type.includes('router') ||
  type.includes('settings')

export default function actionBlocker() {
  return ({ dispatch, getState }) => {
    return next =>  action => {
      const logged = getState().auth.authUser;

      if (logged) {
        return next(action);
      }

      if (allowedAction(action.type)) {
        return next(action);
      }

    };
  };
}
