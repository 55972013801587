import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { getAds } from 'store/services/ad';
import { normalize } from 'normalizr';
import { adSchema } from 'store/schemas';

const actionPrefix = 'ad';

export const GET_ADS_REQUEST = `${actionPrefix}/GET_ADS_REQUEST`;
export const GET_ADS_SUCCESS = `${actionPrefix}/GET_ADS_SUCCESS`;
export const GET_ADS_FAILURE = `${actionPrefix}/GET_ADS_FAILURE`;
export const GET_ADS_END = `${actionPrefix}/GET_ADS_END`;

const INIT_STATE = {
  fetched: false,
  loading: false,
  list: {},
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_ADS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADS_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: {
          ...state.list,
          ...action.result.entities.ad,
        },
      };
    case GET_ADS_END:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function getAdsRequest (isDraft = false) {
  return {
    types: [
      GET_ADS_REQUEST,
      GET_ADS_SUCCESS,
      GET_ADS_FAILURE,
      GET_ADS_END,
    ],
    promise: (getState, dispatch) => ajax(
      getAds(isDraft)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];
        return normalize(response.map((response) => ({ ...response, isDraft })), [adSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}
