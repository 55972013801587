import React from 'react';
import { Upload, Icon} from 'antd';
import PropTypes from 'prop-types';


const UploadDragger = ({name = 'files', handleUpload, ...props}) =>
  <div className="arpec-upload-dragger">
    <Upload.Dragger name={name} beforeUpload={() => false} onChange={handleUpload} {...props}>
      <p className="ant-upload-drag-icon">
        <Icon type={props.icon ? props.icon : 'paper-clip'} />
      </p>
      <p className="ant-upload-text">{props.label}</p>
      <p className="ant-upload-hint">{props.instructions}</p>
    </Upload.Dragger>
  </div>

UploadDragger.propTypes = {
  handleUpload: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  instructions: PropTypes.string,
};


export default UploadDragger;
