import { schema } from 'normalizr';

export const notificationSchema = new schema.Entity('notification');

export const quoteSchema = new schema.Entity('quote');

export const contractSchema = new schema.Entity('contract');

export const knowledgeSchema = new schema.Entity('knowledge');

export const tokenSchema = new schema.Entity('token');
export const subscribedProductSchema = new schema.Entity('subscribedProduct');
export const contactSchema = new schema.Entity('contact');

export const exchangeSchema = new schema.Entity('exchange');

export const adSchema = new schema.Entity('ad');

export const cmsSchema = new schema.Entity('cms', {}, {
  idAttribute: 'identifier',
});

export const incidentSchema = new schema.Entity('incident', {
  exchanges: [exchangeSchema],
});

export const taskSchema = new schema.Entity('task', {
  exchanges: [exchangeSchema],
});

export const catalogueCategorySchema = new schema.Entity('catalogueCategory', {}, {
  mergeStrategy: (a, b) => ({
    ...a,
    ...b,
    articles: [...new Set([...a.articles, ...b.articles])].map(Number),
  }),
  processStrategy: (value, parent, key) => {
    if (!Array.isArray(value.articles)) {
      value.articles = [parent.id];
    } else {
      value.articles.push(parent.id);
    }

    return {
      ...value,
      articles: [...new Set([...value.articles])].map(Number),
    };
  },
});

export const articleSchema = new schema.Entity('article', {
  catalogueCategory: catalogueCategorySchema,
});

export const productSchema = new schema.Entity('product', {
  articles: [articleSchema],
});

export const downloadSchema = new schema.Entity('download');
export const panelSchema = new schema.Entity('panel', {
  downloads: [downloadSchema],
});

export const clientSchema = new schema.Entity('client', {
  incidents: [incidentSchema],
  subscribedProducts: [subscribedProductSchema],
  contacts: [contactSchema],
  tasks: [taskSchema],
  products: [productSchema],
  tokens: [tokenSchema],
  panels: [panelSchema]
});
