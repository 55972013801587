import React, { PureComponent } from 'react'
import { Icon as IconAntd } from "antd"

class Icon extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
        path: null,
    }
  }

  componentDidMount() {
    const { name } = this.props

    if(name) {
        import(`assets/icons/${name}.svg`).then((svg) => {
            this.setState({
              path: svg.default,
            })
          })
    }
    
  }

  render() {
    const { path } = this.state
    const { type, name, className, src, alt } = this.props

    if (type) {
        return <IconAntd type={type} className={className}/>
    }

    return (
      <img className={className ? className : ''} src={src ? src : path} alt={`icon ${alt ? alt : name}`}/>
    )
  }
}

export default Icon
