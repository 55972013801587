import { ajax } from 'rxjs/ajax'
import { map, catchError } from 'rxjs/operators';
import { getPanelDownloads } from 'store/services/download'
import { updatePanel } from 'store/ducks/Panel'
import { normalize } from 'normalizr';
import { downloadSchema } from "store/schemas";

export const GET_PANEL_DOWNLOADS_REQUEST = 'download/GET_PANEL_DOWNLOADS_REQUEST';
export const GET_PANEL_DOWNLOADS_SUCCESS = 'download/GET_PANEL_DOWNLOADS_SUCCESS';
export const GET_PANEL_DOWNLOADS_FAILURE = 'download/GET_PANEL_DOWNLOADS_FAILURE';
export const GET_PANEL_DOWNLOADS_END = 'download/GET_PANEL_DOWNLOADS_END';

const INIT_STATE = {
  fetched: [],
  loading: false,
  submitting: false,
  list: {}
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_PANEL_DOWNLOADS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_PANEL_DOWNLOADS_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ?[...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: {
          ...state.list,
          ...action.result.entities.download
        }
      }
    case GET_PANEL_DOWNLOADS_FAILURE:
        return {
          ...state,
        }
    case GET_PANEL_DOWNLOADS_END:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}


export function getDownloadOfPanelRequest(panelId) {
  return {
    types: [
      GET_PANEL_DOWNLOADS_REQUEST,
      GET_PANEL_DOWNLOADS_SUCCESS,
      GET_PANEL_DOWNLOADS_FAILURE,
      GET_PANEL_DOWNLOADS_END
    ],
    promise: (getState, dispatch) => ajax(
      getPanelDownloads(panelId)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        dispatch(updatePanel({
          ...getState().panel.list[panelId],
          downloads: response
        }))

        return {
          ...normalize(response, [downloadSchema]),
          fetched: panelId
        }
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}
