import React from 'react';
import { Redirect } from 'react-router'


export const hasRight = (roles, auth) => Array.isArray(roles) ? roles.every((role) => auth[role]) : auth[roles];

const WithCapacity = WrappedComponent => ({roles = [], auth, ...props}) => {

  if(!hasRight(roles, auth)) {
    return <Redirect to="/access-denied"/>
  }

  return <WrappedComponent {...props} />
}

export default WithCapacity;
