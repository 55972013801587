

export function getClientProducts(id) {
  return {
    method: 'GET',
    url: `/api/clients/${id}/catalogue`,
    headers: {
      Authorization: true
    },
  }
}

export function getProduct(id) {
  return {
    method: 'GET',
    url: `/api/products/${id}`,
    headers: {
      Authorization: true
    },
  }
}
