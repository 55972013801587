import React from "react"
import { Row, Col } from "antd";
import { Link } from "react-router-dom"

import logo from 'assets/images/logo.svg';
import cover from 'assets/images/cover.jpg';

import { Button} from 'views/components/atoms'

class SignupSuccess extends React.Component {

  render() {

    return (
      <Row className="arpec-auth-container">
        <Col span={12}>

          <div className="gx-text-center gx-mt-4">
            <Link className="arpec-auth-logo" to="/">
              <img  alt="Logo Arpège" src={logo}/>
            </Link>
          </div>

          <Col span={12} className="arpec-auth-form">

            <Row className="gx-mt-100">
              <Col span={24}>
                <h1 class="arpec-h1">CRÉATION DE COMPTE</h1>
                <p class="gx-text-muted">Votre demande a bien été prise en compte</p>
                <p class="gx-text-muted">Elle sera traitée dans les plus brefs délais</p>
                <p><Link to="/signin">Revenir à la page de connexion</Link></p>
              </Col>
            </Row>
            <Row className="gx-mt-100">
              <Col span={12}>
                <Button type="default" className="gx-w-100">
                  Télémaintenance
                </Button>
              </Col>
              <Col span={12}>
                <Link to="/private-access">
                  <Button type="default" className="gx-w-100">
                    Formation
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Col>
        <Col className="arpec-auth-cover" span={12}>
          <img src={cover} alt="présentation" />
        </Col>
      </Row>


    )
  }

}

export default SignupSuccess;