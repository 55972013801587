import React from "react"
import { Button as ButtonAntd }  from "antd"

class Button extends React.Component {

  render() {
    const { type, brand = '', className = '', children, muted, disabled, ...props } = this.props

    return (
        <>
            <ButtonAntd
            className={`${brand} ${className}`}
            type={type}
            children={children} disabled={disabled} {...props} />
            {muted && (<p className="gx-text-muted gx-text-center gx-mt-2">{muted}</p>)}
        </>
    )
  }
}

export default Button;
