import React from "react";
import { Icon, Spin } from 'antd';
import { Download, Token } from 'views/components/icons';
import Colors from 'constants/Colors';
import { isExternal, getRelativePath } from 'util/links';
import moment from 'moment';
import { Link } from 'react-router-dom';
import 'moment/locale/fr';
moment.locale('fr')

function getNotificationIcon(type, isRead) {
  const color = isRead ? Colors.gray1 : Colors.pink1;
  const fontSize = 17;

  switch (type) {
    case 'Communication': return <Icon type="message" style={{color, fontSize}} />;
    case 'Incident': return <Icon type="check" style={{color, fontSize}} />;
    case 'MiseAJour': return <Download color={color}/>;
    case 'AssistanceConfort': return <Token color={color} /> ;
    default: return <Icon type="notification" style={{color, fontSize}} />;
  }
}



const NoticiationIcon = ({type, isRead}) => {
  const Icon = getNotificationIcon(type, isRead);
  return (
    <div className={`gx-wf-60 gx-text-center arpec-notification-icon`}>
      <div className={`arpec-notification-icon-inner ${!isRead && ('arpec-notification-icon-not-read')}`}>
        {Icon}
      </div>
    </div>
  )
}


const NotificationItem = ({readNotifications, notification, loadingIds}) => {
  const {subject, createdAt, content, type, isNotRead, id, url} = notification;
  const isRead = !isNotRead;

  const notificationContent = (
    <li className="gx-media">
      <NoticiationIcon type={type} isRead={isRead} />
      <div className="gx-media-body gx-align-self-center">
        <p className="arpec-notification-item-title">{subject}</p>
        <p className="arpec-notification-item-text">{content}</p>
        <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
          <p className="arpec-notification-item-date">{moment(createdAt).fromNow()}</p>
          {!isRead && (<p onClick={() => readNotifications([id])} className="arpec-notification-item-action">Marquer comme lu</p>)}
        </div>
      </div>
    </li>
  )

  return (
    <Spin spinning={loadingIds.includes(id)}>
      <div className="arpec-notification-item">
        {
          isExternal(url)
            ? <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => !isRead ? readNotifications([id]) : undefined}
            >
              {notificationContent}
            </a>
            : <Link to={getRelativePath(url)} onClick={() => !isRead ? readNotifications([id]) : undefined}>
              {notificationContent}
            </Link>
        }
      </div>
    </Spin>
  );
};

export default NotificationItem;
