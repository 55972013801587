import React from "react";
import { Form, Row, Col } from "antd";
import { Link } from "react-router-dom";

import { InputText, Button } from 'views/components/atoms'

import logo from 'assets/images/logo.svg';
import cover from 'assets/images/cover.jpg';
import {connect} from "react-redux";
import {forgotPasswordRequest} from "store/ducks/Auth";

class ForgotPassword extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.forgotPassword(values);
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    return (

      <Row className="arpec-auth-container">
        <Col span={12}>

          <div className="gx-text-center gx-mt-4">
            <Link className="arpec-auth-logo" to="/">
              <img  alt="Logo Arpège" src={logo}/>
            </Link> 
          </div>

          <Col span={12} className="arpec-auth-form">

            <Row className="gx-mt-100">
              <Col span={24}>
                <h1 class="arpec-h1">Mot de passe oublié</h1>
                <p class="gx-text-muted">Merci d’entrer votre identifiant afin que nous puissions vous renvoyer un mot de passe.</p>
              </Col>

              <Col className="gx-mt-4" span={24}>
                
              <Form onSubmit={this.handleSubmit} className="login-form">

                <Form.Item label="Identifiant" className="gw-w-100">
                  {
                    getFieldDecorator('username', {
                      rules: [
                        {
                          required: true,
                          message: "Merci de saisir votre identifiant de connexion",
                        }
                      ],
                    })(<InputText data-cy="username-input" />)
                  }
                </Form.Item>
                <Form.Item className="gx-text-center">
                  <Button type="primary" htmlType="submit" className="gx-w-100">
                    Envoyer
                  </Button>
                  <Link to="/signin">
                    <Button type="link" className="gx-mt-4 gx-w-100">
                      Annuler
                    </Button>
                  </Link>
                </Form.Item>
              </Form>

              </Col>
            </Row> 

          </Col>
          
        </Col>
        <Col className="arpec-auth-cover" span={12}>
            <img src={cover} alt="présentation" />
        </Col>
      </Row>

      
    )
  }
}

const mapStateToProps = (state, props) => ({
  loading: state.auth.loading,
})

const mapDispatchToProps = dispatch => ({
  forgotPassword: (payload) => dispatch(forgotPasswordRequest(payload))
})

const WrappedForgotPassword = Form.create({ name: 'forgotPassword' })(ForgotPassword);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedForgotPassword);
