import React, { Component } from "react";
import { Layout, Popover, Spin } from "antd";
import { Link } from "react-router-dom";

import AppNotification from "./AppNotification";
import Auxiliary from "util/Auxiliary";
import FormCart from "./FormCart"

import logo from 'assets/images/logo.svg';
import notifications from 'assets/images/icons/notifications.svg';
import cart from 'assets/images/icons/cart.svg';

import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "constants/ThemeSetting";

const { Header } = Layout;

class Topbar extends Component {

  render() {
    const { notificationFetched, hasItemShoppingCart, hasUnReadNotification, width, navStyle, cartVisible, toggleCartVisible, profile } = this.props;

    return (
      <Auxiliary>
        <Header>
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar gx-mr-3">
              <i className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   this.props.toggleCollapsedSideNav();
                 }}
              />
            </div> : null}
          <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
            <img alt="" src={logo}/></Link>
          <ul className="gx-header-notifications gx-ml-auto">
              <Auxiliary>
                <li className="gx-notify">
                  <Spin spinning={!notificationFetched}>
                    <Popover overlayClassName="gx-popover-horizantal arpec-app-notification" placement="bottomRight" content={<AppNotification/>}
                             trigger="click">
                      <span className="gx-pointer gx-status-pos gx-d-block">
                      <i className="icon"><img src={notifications} alt=""/></i>
                      { hasUnReadNotification && (<span className="gx-status gx-status-rtl gx-small gx-orange"/>)}
                    </span>
                    </Popover>
                  </Spin>
                </li>
                <li className="gx-shop">
                  <Popover visible={cartVisible} onVisibleChange={() => toggleCartVisible(!cartVisible)} overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<FormCart toggleCartVisible={toggleCartVisible} />} trigger="click">
                    <span className="gx-pointer gx-status-pos gx-d-block">
                      <i className="icon"><img src={cart} alt=""/></i>
                      { hasItemShoppingCart && (<span className="gx-status gx-status-rtl gx-small gx-orange"/>)}
                    </span>
                  </Popover>
                </li>
              </Auxiliary>
          </ul>
        </Header>
      </Auxiliary>
    );
  }
}

export default Topbar;
