import React from "react"
import { Select as SelectAntd }  from "antd"
import { slugify } from 'util/functions';

const OptionAntd = SelectAntd.Option;


const Select = ({isForm, label, options, className, defaultValue, ...props}) => {

  if (!isForm) {
    return (
      <div className={`arpec-input-container ${className ? className : ''}`}>
        <label className="arpec-input-label">{ label }</label>
        <SelectAntd key={`${Math.random()}`} className="arpec-select gx-w-100" defaultValue={defaultValue ? defaultValue : options[0] ? options[0].value : null} {...props}>
          {
            options && (options.map((option, index) => <OptionAntd disabled={option.disabled || false} key={`${option.value}-${Math.random()}`} value={option.value}><span data-cy={`client-selector-${slugify(option.label || '')}`}>{option.label}</span></OptionAntd>))
          }
        </SelectAntd>
      </div>
    )
  }


  return (
    <SelectAntd className="gx-w-100" {...props}>
      {
        options && (options.map((option, index) => <OptionAntd disabled={option.disabled} key={option.value} value={option.value}><span data-cy={`client-selector-${slugify(option.label || '')}`}>{option.label}</span></OptionAntd>)
        )
      }
    </SelectAntd>
  )
}

export default Select
