export function getKnowledges(search, filter) {

  let url = `/api/knowledge?1=1`
  if(search) {
    url = url.concat(`&search=${search}`)
  }
  if(filter) {
    url = url.concat(`&filter=${filter}`)
  }

  return {
    method: 'GET',
    url: url,
    headers: {
      Authorization: true
    },
  }
}

export function getKnowledgesMostViewed() {
  return {
    method: 'GET',
    url: `/api/knowledge/mostviewed`,
    headers: {
      Authorization: true
    },
  }
}

export function getKnowledge(id) {
  return {
    method: 'GET',
    url: `/api/knowledge/${id}`,
    headers: {
      Authorization: true
    },
  }
}
export function getKnowledgesCategories() {
  return {
    method: 'GET',
    url: `/api/knowledge/categories`,
    headers: {
      Authorization: true
    },
  }
}

export function getKnowledgeAttachment(id) {
  return {
    method: 'GET',
    url: `/api/knowledge/${id}/files`,
    headers: {
      Authorization: true
    },
  }
}
