import { ajax } from 'rxjs/ajax'
import {map, catchError} from 'rxjs/operators';
import { getClientContact, getContacts, getContact, updateRoleOfContact, updateContact, disableContact, deleteContact } from 'store/services/contact'
import { updateClient } from 'store/ducks/Client'
import { normalize } from 'normalizr';
import { contactSchema } from "store/schemas";
import { openNotificationByType } from 'util/Notification';
import omit from 'lodash.omit';
import { push } from 'react-router-redux';

export const GET_CONTACT_CLIENT_REQUEST = 'contact/GET_CONTACT_CLIENT_REQUEST';
export const GET_CONTACT_CLIENT_SUCCESS = 'contact/GET_CONTACT_CLIENT_SUCCESS';
export const GET_CONTACT_CLIENT_FAILURE = 'contact/GET_CONTACT_CLIENT_FAILURE';
export const GET_CONTACT_CLIENT_END = 'contact/GET_CONTACT_CLIENT_END';

export const GET_CONTACT_REQUEST = 'contact/GET_CONTACT_REQUEST';
export const GET_CONTACT_SUCCESS = 'contact/GET_CONTACT_SUCCESS';
export const GET_CONTACT_FAILURE = 'contact/GET_CONTACT_FAILURE';
export const GET_CONTACT_END = 'contact/GET_CONTACT_END';

export const UPDATE_ROLE_CONTACT_REQUEST = 'contact/UPDATE_ROLE_CONTACT_REQUEST';
export const UPDATE_ROLE_CONTACT_SUCCESS = 'contact/UPDATE_ROLE_CONTACT_SUCCESS';
export const UPDATE_ROLE_CONTACT_FAILURE = 'contact/UPDATE_ROLE_CONTACT_FAILURE';
export const UPDATE_ROLE_CONTACT_END = 'contact/UPDATE_ROLE_CONTACT_EN';

export const UPDATE_CONTACT_REQUEST = 'contact/UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_SUCCESS = 'contact/UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILURE = 'contact/UPDATE_CONTACT_FAILURE';
export const UPDATE_CONTACT_END = 'contact/UPDATE_CONTACT_EN';

export const DISABLE_CONTACT_REQUEST = 'contact/DISABLE_CONTACT_REQUEST';
export const DISABLE_CONTACT_SUCCESS = 'contact/DISABLE_CONTACT_SUCCESS';
export const DISABLE_CONTACT_FAILURE = 'contact/DISABLE_CONTACT_FAILURE';
export const DISABLE_CONTACT_END = 'contact/DISABLE_CONTACT_EN';

export const DELETE_CONTACT_REQUEST = 'contact/DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'contact/DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'contact/DELETE_CONTACT_FAILURE';
export const DELETE_CONTACT_END = 'contact/DELETE_CONTACT_EN';

export const SELECT_CONTACT = 'contact/SELECT_CONTACT';

const INIT_STATE = {
  fetched: [],
  loading: false,
  updating: false,
  selected: null,
  list: {}
};


export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_CONTACT_CLIENT_REQUEST :
    case GET_CONTACT_REQUEST :
    case UPDATE_ROLE_CONTACT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case DELETE_CONTACT_REQUEST:
    case DISABLE_CONTACT_REQUEST:
    case UPDATE_CONTACT_REQUEST: {
      return {
        ...state,
        updating: true
      }
    }
    case DISABLE_CONTACT_SUCCESS:
    case UPDATE_CONTACT_SUCCESS:
    case UPDATE_ROLE_CONTACT_SUCCESS:
    case GET_CONTACT_CLIENT_SUCCESS:
    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: {
          ...state.list,
          ...action.result.entities.contact
        },
      }
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        list: {
          list: omit(state.list, action.context)
        }
      }
    case UPDATE_ROLE_CONTACT_END:
    case GET_CONTACT_CLIENT_END:
    case GET_CONTACT_END:
      return {
        ...state,
        loading: false
      }
    case DELETE_CONTACT_END:
    case DISABLE_CONTACT_END:
    case UPDATE_CONTACT_END:
      return {
        ...state,
        updating: false
      }
    case SELECT_CONTACT:
      return {
        ...state,
        selected: action.result
      }
    default:
      return state;
  }
}


export function getContactOfClientRequest(clientId) {
  return {
    types: [
      GET_CONTACT_CLIENT_REQUEST,
      GET_CONTACT_CLIENT_SUCCESS,
      GET_CONTACT_CLIENT_FAILURE,
      GET_CONTACT_CLIENT_END
    ],
    promise: (getState, dispatch) => ajax(
      getClientContact(clientId)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        dispatch(updateClient({
          ...getState().client.list[clientId],
          contacts: response
        }))

        return {
          ...normalize(response, [contactSchema]),
          fetched: clientId
        }
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function getContactRequest(contactId) {
  return {
    types: [
      GET_CONTACT_REQUEST,
      GET_CONTACT_SUCCESS,
      GET_CONTACT_FAILURE,
      GET_CONTACT_END
    ],
    promise: (getState, dispatch) => ajax(
      getContact(contactId)
    ).pipe(
      map((res) => {
        const response = res.response;

        return normalize([response], [contactSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function updateRoleOfContactRequest(contactId, role, value) {
  return {
    types: [
      UPDATE_ROLE_CONTACT_REQUEST,
      UPDATE_ROLE_CONTACT_SUCCESS,
      UPDATE_ROLE_CONTACT_FAILURE,
      UPDATE_ROLE_CONTACT_END
    ],
    promise: (getState, dispatch) => ajax(
      updateRoleOfContact(contactId, role, value)
    ).pipe(
      map((res) => {
        const response = res.response;

        return normalize([response], [contactSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function updateContactRequest(contactId, payload) {
  return {
    types: [
      UPDATE_CONTACT_REQUEST,
      UPDATE_CONTACT_SUCCESS,
      UPDATE_CONTACT_FAILURE,
      UPDATE_CONTACT_END
    ],
    promise: (getState, dispatch) => ajax(
      updateContact(contactId, payload)
    ).pipe(
      map((res) => {
        const response = res.response;

        openNotificationByType(
          'success',
          "Modification de l'interlocuteur",
          'Les modifications ont été effectuées avec succès.',
          3
        );


        return normalize([response], [contactSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function disableContactRequest(contactId) {
  return {
    types: [
      DISABLE_CONTACT_REQUEST,
      DISABLE_CONTACT_SUCCESS,
      DISABLE_CONTACT_FAILURE,
      DISABLE_CONTACT_END
    ],
    promise: (getState, dispatch) => ajax(
      disableContact(contactId)
    ).pipe(
      map((res) => {
        const response = res.response;

        openNotificationByType(
          'success',
          `${response.isInternalAuth ? 'Activation' : 'Désactivation'} de l'interlocuteur`,
          'Les modifications ont été effectuées avec succès.',
          3
        );

        return normalize([response], [contactSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function deleteContactRequest(contactId) {
  return {
    types: [
      DELETE_CONTACT_REQUEST,
      DELETE_CONTACT_SUCCESS,
      DELETE_CONTACT_FAILURE,
      DELETE_CONTACT_END
    ],
    context: contactId,
    promise: (getState, dispatch) => ajax(
      deleteContact(contactId)
    ).pipe(
      map((res) => {

        openNotificationByType(
          'success',
          "Suppression de l'interocuteur",
          'Les modifications ont été effectuées avec succès.',
          3
        );

        dispatch(push('/entities'))

      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function selectContact(contactId) {
  return {
    type: SELECT_CONTACT,
    result: contactId
  }
}
