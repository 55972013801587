import { JWT_STORAGE } from 'store/ducks/Auth'
import jwtDecode from 'jwt-decode';


export const getStatusLabel = status => {
  let label = ''
  switch(status) {
    case 'Waiting for Customer':
      label = "Attente client"
      break
    case 'Active':
      label = "Ouvert"
      break
    case 'Waiting for Resolution':
      label = "Attente resolution"
      break
    case 'Waiting for 3rd Party':
      label = "En attente d'un tiers"
      break
    case 'Resolved':
      label = "Résolu"
      break
    case 'Logged':
      label = "Soumis"
      break
    case 'Closed':
      label = "Fermé"
      break
    default:
      label = ""
      break
  }
  return label;
}

export const getStatusColor = status => {
  let color = ''
  switch(status) {
    case 'Waiting for Customer':
      color = 'red'
      break
    case 'Active':
      color = 'pink-0'
      break
    case 'Assigned':
    case 'Waiting for Resolution':
    case 'Waiting for 3rd Party':
      color = 'blue-2'
      break
    case 'Resolved':
      color = 'green'
      break
    case 'Logged':
      color = 'blue-0'
      break
    case 'Cancelled':
    case 'Closed':
      color = 'gray-3'
      break
    case 'Completed':
      color = 'green'
      break
    default:
      color = ''
      break
  }
  return color;
}

export const getStatusDescription = status => {
  let description = ''
  switch(status) {
    case 'Waiting for Customer':
      description = 'En attente d’une réponse de votre part'
      break
    case 'Active':
      description = 'En cours de traitement par un technicien'
      break
    case 'Assigned':
    case 'Waiting for Resolution':
    case 'Waiting for 3rd Party':
      description = 'En attente d’une réponse de notre service développement'
      break
    case 'Resolved':
    case 'Completed':
      description = 'Résolu depuis moins de 7 jours, peut-être réouvert'
      break
    case 'Logged':
      description = 'En attente d’une prise en charge'
      break
    case 'Cancelled':
    case 'Closed':
      description = 'Résolution conforme, ne peut pas être réouvert'
      break
    default:
      description = ''
      break
  }
  return description;
}

export const getStatusOrder = status => {
  let order = 8
  switch(status) {
    case 'Logged':
      order = 0
      break
    case 'Active':
      order = 1
      break
    case 'Waiting for Customer':
      order = 2
      break
    case 'Waiting for 3rd Party':
      order = 3
      break
    case 'Waiting for Resolution':
      order = 4
      break
    case 'Assigned':
      order = 5
      break
    case 'Resolved':
      order = 6
      break
    case 'Completed':
      order = 7
      break
    case 'Closed':
      order = 8
      break
    case 'Cancelled':
      order = 9
      break
    default:
      order = 9
      break
  }
  return order;
}


export const slugify = text => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};



export const hasJWT = () => localStorage.hasOwnProperty(JWT_STORAGE)

export const jwtExpired = () => {
  const { exp } = jwtDecode(localStorage.getItem(JWT_STORAGE))

  const UTC = 3600 * 2; // UTC+2

  return Math.floor(Date.now() / 1000) + UTC > exp;
}

export const userJwtID = () => {
  const { id } = jwtDecode(localStorage.getItem(JWT_STORAGE));
  return id;
};
