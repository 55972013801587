
export function getClients(clientId) {
  return {
    method: 'GET',
    url: `/api/clients/${clientId}/clients`,
    headers: {
      Authorization: true
    },
  }
}
