import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { getTask, getClientTask, getTaskCategories, postTask, postTaskAttachment } from 'store/services/task';
import { updateClient } from 'store/ducks/Client';
import { normalize } from 'normalizr';
import { taskSchema } from 'store/schemas';
import merge from 'lodash/merge';
import { openNotificationByType } from 'util/Notification';
import { push } from 'react-router-redux'

export const GET_CLIENT_TASK_REQUEST = 'task/GET_CLIENT_TASK_REQUEST';
export const GET_CLIENT_TASK_SUCCESS = 'task/GET_CLIENT_TASK_SUCCESS';
export const GET_CLIENT_TASK_FAILURE = 'task/GET_CLIENT_TASK_FAILURE';
export const GET_CLIENT_TASK_END = 'task/GET_CLIENT_TASK_END';

export const GET_TASK_REQUEST = 'task/GET_TASK_REQUEST';
export const GET_TASK_SUCCESS = 'task/GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'task/GET_TASK_FAILURE';
export const GET_TASK_END = 'task/GET_TASK_END';

export const UPDATE_TASK = 'task/UPDATE_TASK';

export const GET_TASK_CATEGORIES_REQUEST = 'task/GET_TASK_CATEGORIES_REQUEST'
export const GET_TASK_CATEGORIES_SUCCESS = 'task/GET_TASK_CATEGORIES_SUCCESS'
export const GET_TASK_CATEGORIES_FAILURE = 'task/GET_TASK_CATEGORIES_FAILURE'
export const GET_TASK_CATEGORIES_END = 'task/GET_TASK_CATEGORIES_END'

export const POST_TASK_REQUEST = 'task/POST_TASK_REQUEST'
export const POST_TASK_SUCCESS = 'task/POST_TASK_SUCCESS'
export const POST_TASK_FAILURE = 'task/POST_TASK_FAILURE'
export const POST_TASK_END = 'task/POST_TASK_END'

export const POST_TASK_ATTACHMENT_REQUEST = 'task/POST_TASK_ATTACHMENT_REQUEST';
export const POST_TASK_ATTACHMENT_SUCCESS = 'task/POST_TASK_ATTACHMENT_SUCCESS';
export const POST_TASK_ATTACHMENT_FAILURE = 'task/POST_TASK_ATTACHMENT_FAILURE';
export const POST_TASK_ATTACHMENT_END = 'task/POST_TASK_ATTACHMENT_END';

export const SELECT_CATEGORY = 'task/SELECT_CATEGORY'

const INIT_STATE = {
  fetched: [],
  loading: false,
  list: {},
  submitting: false,
  categoriesFetched: false,
  selectedCategory: '',
  categories: [],
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case POST_TASK_ATTACHMENT_REQUEST:
    case POST_TASK_REQUEST: {
      return {
        ...state,
        submitting: true
      }
    }
    case GET_TASK_REQUEST:
    case GET_CLIENT_TASK_REQUEST:
    case GET_TASK_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_TASK:
    case GET_TASK_SUCCESS:
    case POST_TASK_SUCCESS:
    case POST_TASK_ATTACHMENT_SUCCESS:
    case GET_CLIENT_TASK_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: merge({}, state.list, action.result.entities.task),
      };
    case GET_TASK_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.result,
        categoriesFetched: true
      }
    case GET_TASK_END:
    case GET_CLIENT_TASK_END:
    case GET_TASK_CATEGORIES_END:
      return {
        ...state,
        loading: false,
      };

    case SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.result
      }
    case POST_TASK_ATTACHMENT_END:
    case POST_TASK_END: {
      return {
        ...state,
        submitting: false
      }
    }
    default:
      return state;
  }
}

export function getTaskRequest (taskId) {
  return {
    types: [
      GET_TASK_REQUEST,
      GET_TASK_SUCCESS,
      GET_TASK_FAILURE,
      GET_TASK_END,
    ],
    promise: () => ajax(
      getTask(taskId)
    ).pipe(
      map(({ response }) => {
        response.complete = true;
        return normalize([response], [taskSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function selectCategory(name) {
  return {
    type: SELECT_CATEGORY,
    result: name
  }
}

export function getTaskCategoriesRequest() {
  return {
    types: [
      GET_TASK_CATEGORIES_REQUEST,
      GET_TASK_CATEGORIES_SUCCESS,
      GET_TASK_CATEGORIES_FAILURE,
      GET_TASK_CATEGORIES_END
    ],
    promise: (getState, dispatch) => ajax(
      getTaskCategories()
    ).pipe(
      map((res) => res.response),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}


export function postTaskRequest(payload) {
  return {
    types: [
      POST_TASK_REQUEST,
      POST_TASK_SUCCESS,
      POST_TASK_FAILURE,
      POST_TASK_END
    ],
    promise: (getState, dispatch) => ajax(
      postTask(payload)
    ).pipe(
      map((res) => {
        const response = res.response;

        dispatch(updateClient({
          ...getState().client.list[payload.entity],
          tasks: [
            ...getState().client.list[payload.entity].tasks,
            response
          ]
        }))

        openNotificationByType(
          'success',
          `Demande d'assistance`,
          `La demande d'assistance confort à été crée avec succès`,
          5
        );

        dispatch(push('/tasks'));

        return normalize([response], [taskSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function getTaskOfClientRequest (clientId) {
  return {
    types: [
      GET_CLIENT_TASK_REQUEST,
      GET_CLIENT_TASK_SUCCESS,
      GET_CLIENT_TASK_FAILURE,
      GET_CLIENT_TASK_END,
    ],
    promise: (getState, dispatch) => ajax(
      getClientTask(clientId)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        dispatch(updateClient({
          ...getState().client.list[clientId],
          tasks: response,
        }));

        return {
          ...normalize(response, [taskSchema]),
          fetched: clientId,
        };
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}


export function postTaskAttachmentRequest(taskId, file) {
  return {
    types: [
      POST_TASK_ATTACHMENT_REQUEST,
      POST_TASK_ATTACHMENT_SUCCESS,
      POST_TASK_ATTACHMENT_FAILURE,
      POST_TASK_ATTACHMENT_END
    ],
    promise: (getState, dispatch) => ajax(
      postTaskAttachment(taskId, file)
    ).pipe(
      map((res) => {
        const files = res.response;

        openNotificationByType(
          'success',
          `Ajout de la pièce jointe`,
          `La pièce jointe à été ajoutée avec succès`,
          5
        );

        const response = {
          ...getState().task.list[taskId],
          files
        }

        return normalize([response], [taskSchema])
      }),
      catchError((error) => {
        openNotificationByType(
          'error',
          "Une erreur s'est produite",
          "Veuillez contacter un administrateur pour plus d'information",
          5
        );
        return Promise.reject(error)
      }),
    ).toPromise()
  }
}


export function updateTask (task) {
  return {
    type: UPDATE_TASK,
    result: normalize(task, taskSchema),
  };
}
