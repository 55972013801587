import { ajax } from 'rxjs/ajax'
import {map, catchError} from 'rxjs/operators';
import { getClientToken, getTokens, getToken, updateRoleOfToken } from 'store/services/token'
import { updateClient } from 'store/ducks/Client'
import { normalize } from 'normalizr';
import { tokenSchema } from "store/schemas";

export const GET_TOKEN_CLIENT_REQUEST = 'token/GET_TOKEN_CLIENT_REQUEST';
export const GET_TOKEN_CLIENT_SUCCESS = 'token/GET_TOKEN_CLIENT_SUCCESS';
export const GET_TOKEN_CLIENT_FAILURE = 'token/GET_TOKEN_CLIENT_FAILURE';
export const GET_TOKEN_CLIENT_END = 'token/GET_TOKEN_CLIENT_END';


const INIT_STATE = {
  fetched: [],
  loading: false,
  selected: null,
  list: {}
};


export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_TOKEN_CLIENT_REQUEST :
      return {
        ...state,
        loading: true
      }
    case GET_TOKEN_CLIENT_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: {
          ...state.list,
          ...action.result.entities.token
        },
      }
    case GET_TOKEN_CLIENT_END:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}


export function getTokenOfClientRequest(clientId) {
  return {
    types: [
      GET_TOKEN_CLIENT_REQUEST,
      GET_TOKEN_CLIENT_SUCCESS,
      GET_TOKEN_CLIENT_FAILURE,
      GET_TOKEN_CLIENT_END
    ],
    promise: (getState, dispatch) => ajax(
      getClientToken(clientId)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        dispatch(updateClient({
          ...getState().client.list[clientId],
          tokens: response
        }))

        return {
          ...normalize(response, [tokenSchema]),
          fetched: clientId
        }
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}
