

export function getProductArticle(id) {
  return {
    method: 'GET',
    url: `/api/products/${id}/articles`,
    headers: {
      Authorization: true
    },
  }
}
