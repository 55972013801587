import React from 'react';
import { Modal } from "antd";
import { push } from 'react-router-redux'
import { toggleCartVisible } from 'store/ducks/Setting'
import {catchError, map, mergeMap} from 'rxjs/operators'
import { ofType, combineEpics } from 'redux-observable'
import { NEVER, of, from } from 'rxjs';
import { getClientRequest } from "store/ducks/Client";
import check from 'assets/images/icons/check.svg';
import { openNotificationByType } from 'util/Notification';
import { sendQuote } from 'store/services/quote';
import uuid from 'uuid/v4';
import omit from 'lodash.omit';
import { store } from 'NextApp'
import {ajax} from "rxjs/ajax";
import { updateQuote } from 'store/ducks/Quote';

const { confirm } = Modal;

export const OPEN_MODAL_CART_SUCCESS = 'cart/OPEN_MODAL_CART_SUCCESS';
export const OPEN_MODAL_ITEM_SUCCESS = 'cart/OPEN_MODAL_ITEM_SUCCESS';

export const ADD_ITEM = 'cart/ADD_ITEM';
export const UPDATE_ITEM = 'cart/UPDATE_ITEM';
export const DELETE_ITEM = 'cart/DELETE_ITEM';
export const RESET_CART = 'cart/RESET_CART';

export const SEND_QUOTE_REQUEST = 'cart/SEND_QUOTE_REQUEST';
export const SEND_QUOTE_FAILURE = 'cart/SEND_QUOTE_FAILURE';

const INIT_STATE = {
  list: [],
  sending: false
};

export function reducer (state = INIT_STATE, action) {
    switch (action.type) {
      case UPDATE_ITEM:
      case ADD_ITEM: {
        return {
          ...state,
          list: {
            ...state.list,
            [action.item.id]: action.item
          }
        }
      }
      case DELETE_ITEM:
        return {
          ...state,
          list: omit(state.list, action.itemId)
        }
      case RESET_CART: {
        return {
          ...state,
          list: []
        }
      }
      case SEND_QUOTE_REQUEST:
        return {
          ...state,
          sending: true
        }
      case SEND_QUOTE_FAILURE:
      case OPEN_MODAL_CART_SUCCESS:
        return {
          ...state,
          sending: false
        }
      default:
        return state;
    }
}

export function openModalCartSuccess () {
    return {
        type: OPEN_MODAL_CART_SUCCESS,
    }
}


export function openModalItemSuccess () {
    return {
        type: OPEN_MODAL_ITEM_SUCCESS,
    }
}

export function sendQuoteRequest (payload) {
    return {
        type: SEND_QUOTE_REQUEST,
        payload
    }
}

export function sendQuoteFailure () {
    return {
        type: SEND_QUOTE_FAILURE,
    }
}

export function addItemRequest(item) {
  return {
    type: ADD_ITEM,
    item: {
      ...item,
      id: uuid()
    }
  }
}

export function updateItemRequest(item) {
  return {
    type: UPDATE_ITEM,
    item
  }
}

export function deleteItemRequest(itemId) {
  return {
    type: DELETE_ITEM,
    itemId
  }
}

export function resetCartRequest() {
  return {
    type: RESET_CART
  }
}

export const epic = combineEpics(
    openModalCartSuccessEpic,
    openModalItemSuccessEpic,
    addItemRequestEpic,
    sendQuoteRequestEpic,
)

export function openModalCartSuccessEpic(action$, state$) {
    return action$.pipe(
        ofType(OPEN_MODAL_CART_SUCCESS),
        mergeMap((action) => {
            const { dispatch } = store;

            const commerceCC = state$.value.client.list[state$.value.auth.clientId].commerceCC;
            const content = commerceCC ? `${commerceCC}, votre chargé(e) de clientèle, va prochainement prendre contact avec vous pour étudier votre demande.` : `Votre chargé(e) de clientèle va prochainement prendre contact avec vous pour étudier votre demande.`

            confirm({
                title: 'Votre devis a bien été envoyé !',
                content,
                okText: 'Revenir au catalogue',
                cancelText: 'Voir mon devis',
                icon: <i className="icon"><img src={check} alt=""/></i>,
                onOk() {
                    dispatch(push('/catalogue'))
                },
                onCancel() {
                    dispatch(push('/quotes/request'))
                },
            });

            return NEVER;
        }),
    )
}

export function openModalItemSuccessEpic(action$) {
    return action$.pipe(
        ofType(OPEN_MODAL_ITEM_SUCCESS),
        mergeMap((action) => {
            const { dispatch } = store;
            confirm({
                title: 'Le service est ajouté au devis !',
                content: 'Que voules vous faire maintenant ?',
                okText: 'Visualiser mon panier',
                cancelText: 'Revenir au catalogue',
                icon: <i className="icon"><img src={check} alt=""/></i>,
                onOk() {
                    dispatch(toggleCartVisible(true))
                },
                onCancel() {
                    return;
                },
            });
          return NEVER
        }),
    )
}

export function sendQuoteRequestEpic(action$, state$) {
  return action$.pipe(
    ofType(SEND_QUOTE_REQUEST),
    mergeMap( ({payload}) =>
      ajax(
        sendQuote(payload, state$.value)
      ).pipe(
        map((res) => [
          toggleCartVisible(false),
          openModalCartSuccess(),
          resetCartRequest(),
          updateQuote(res.response)
        ]),
        catchError(() => {
          openNotificationByType(
            'error',
            "Une erreur s'est produite",
            "Veuillez contacter un administrateur pour plus d'information",
            3
          );
          return of(sendQuoteFailure());
        }),
      ),
    ),
  )
}

export function addItemRequestEpic(action$) {
    return action$.pipe(
        ofType(ADD_ITEM),
        mergeMap((action) => {
          return of(openModalItemSuccess())
        }),
    )
}
