import React from "react";
import { Form, Row, Col } from "antd";
import { Link } from "react-router-dom";

import { InputText, Button} from 'views/components/atoms'

import logo from 'assets/images/logo.svg';
import cover from 'assets/images/cover.jpg';

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    console.log('submit')
  }

  render() {

    return (

      <Row className="arpec-auth-container">
        <Col span={12}>

          <div className="gx-text-center gx-mt-4">
            <Link className="arpec-auth-logo" to="/">
              <img  alt="Logo Arpège" src={logo}/>
            </Link> 
          </div>

          <Col span={12} className="arpec-auth-form">

            <Row className="gx-mt-100">
              <Col span={24}>
                <h1 class="arpec-h1">Formation</h1>
                <p class="gx-text-muted">Veuillez indiquer le code fourni par votre formateur.</p>
              </Col>

              <Col className="gx-mt-4" span={24}>
                
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item className="gw-w-100">
                  <InputText label="Identifiant"
                      placeholder="Username"
                      className="gw-w-100"
                    />
                </Form.Item>
                <Form.Item className="gx-text-center">
                  <Button type="primary" htmlType="submit" className="gx-w-100">
                    Valider
                  </Button>
                  <Link to="/signin">
                    <Button type="link" className="gx-mt-4 gx-w-100">
                      Annuler
                    </Button>
                  </Link>
                </Form.Item>
              </Form>

              </Col>
            </Row> 
          </Col>
          
        </Col>
        <Col className="arpec-auth-cover" span={12}>
            <img src={cover} alt="présentation" />
        </Col>
      </Row>

      
    )
  }
}

export default SignIn
