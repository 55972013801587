import { map, mergeMap, catchError } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import { NEVER, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { login, account, signup, updateNotification, updateAccount, forgotPassword, resetPassword } from 'store/services/auth';
import { openNotificationByType } from 'util/Notification';
import { getNotificationRequest } from './Notification';
import jwtDecode from 'jwt-decode';
import { hasJWT } from 'util/functions';
import { push } from 'react-router-redux';
import omit from 'lodash.omit';
import React from "react";

export const JWT_STORAGE = '@localStorage/jwt';

export const INIT_URL = 'auth/INIT_URL';

export const SAVE_PASSWORD = 'auth/SAVE_PASSWORD';
export const REMOVE_PASSWORD = 'auth/REMOVE_PASSWORD';

export const SIGNUP_REQUEST = 'auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'auth/SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'auth/SIGNUP_FAILURE';

export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'auth/LOGOUT_FAILURE';

export const GET_ACCOUNT_REQUEST = 'auth/GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'auth/GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'auth/GET_ACCOUNT_FAILURE';

export const UPDATE_ACCOUNT_REQUEST = 'auth/UPDATE_ACCOUNT_REQUEST';
export const UPDATE_ACCOUNT_SUCCESS = 'auth/UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'auth/UPDATE_ACCOUNT_FAILURE';
export const UPDATE_ACCOUNT_END = 'auth/UPDATE_ACCOUNT_END';

export const FORGOT_PASSWORD_REQUEST = 'auth/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'auth/FORGOT_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_END = 'auth/FORGOT_PASSWORD_END';

export const RESET_PASSWORD_REQUEST = 'auth/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_END = 'auth/RESET_PASSWORD_END';

export const UPDATE_NOTIFICATION_REQUEST = 'auth/UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'auth/UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'auth/UPDATE_NOTIFICATION_FAILURE';
export const UPDATE_NOTIFICATION_END = 'auth/UPDATE_NOTIFICATION_END';

const INIT_STATE = {
  loading: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: false,
  updating: false,
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ACCOUNT_FAILURE:
    case LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case GET_ACCOUNT_SUCCESS: {
      return {
        ...state,
        authUser: true,
        loading: false,
        ...action.payload,
      };
    }
    case LOGOUT_SUCCESS:
    case LOGOUT_REQUEST: {
      return {
        ...INIT_STATE,
      };
    }
    case UPDATE_NOTIFICATION_REQUEST:
    case UPDATE_ACCOUNT_REQUEST: {
      return {
        ...state,
        updating: true,
      };
    }
    case UPDATE_NOTIFICATION_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case UPDATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        ...action.result,
      };
    }
    case UPDATE_NOTIFICATION_END:
    case UPDATE_ACCOUNT_END: {
      return {
        ...state,
        updating: false,
      };
    }
    case SAVE_PASSWORD: {
      return {
        ...state,
        tempPassword: action.payload,
      };
    }
    case REMOVE_PASSWORD: {
      return {
        ...omit(state, 'tempPassword'),
      };
    }
    default:
      return state;
  }
}

export const savePassword = (password) => {
  return {
    type: SAVE_PASSWORD,
    payload: password,
  };
};
export const removePasswordRequest = () => {
  return {
    type: REMOVE_PASSWORD,
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const loginRequest = (payload) => {
  return {
    type: LOGIN_REQUEST,
    payload,
  };
};

export const loginSuccess = (payload) => {
  return {
    type: LOGIN_SUCCESS,
    payload,
  };
};

export const signupRequest = (payload) => {
  return {
    type: SIGNUP_REQUEST,
    payload,
  };
};

export const logoutRequest = (payload) => {
  return {
    type: LOGOUT_REQUEST,
    payload,
  };
};

export const logoutSuccess = (payload) => {
  return {
    type: LOGOUT_SUCCESS,
    payload,
  };
};

export const logoutFailure = (payload) => {
  return {
    type: LOGOUT_FAILURE,
    payload,
  };
};

export const signupSuccess = (payload) => {
  return {
    type: SIGNUP_SUCCESS,
    payload,
  };
};

export const signupFailure = (payload) => {
  return {
    type: SIGNUP_FAILURE,
    payload,
  };
};

export const loginFailure = (payload) => {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
};

export const getAccountRequest = (payload) => {
  return {
    type: GET_ACCOUNT_REQUEST,
    payload,
  };
};

export const getAccountSuccess = (payload) => {
  return {
    type: GET_ACCOUNT_SUCCESS,
    payload,
  };
};

export const getAccountFailure = (payload) => {
  return {
    type: GET_ACCOUNT_FAILURE,
    payload,
  };
};

export const epic = combineEpics(
  loginRequestEpic,
  loginFailureEpic,
  accountRequestEpic,
  loginSuccessEpic,
  getAccountFailureEpic,
  signupRequestEpic,
  signupSuccessEpic,
  logoutRequestEpic,
);

export function loginRequestEpic (action$) {
  return action$.pipe(
    ofType(LOGIN_REQUEST),
    mergeMap(({ payload }) =>
      ajax(
        login(payload)
      ).pipe(
        map((res) => {
          const { token } = res.response;
          const { id } = jwtDecode(token);

          localStorage.setItem(JWT_STORAGE, token);

          return [
            savePassword(payload.password),
            getAccountRequest(id),
            getNotificationRequest(),
          ];
        }),
        catchError((err) => of(loginFailure(err))),
      ),
    ),
  );
}

export function loginFailureEpic (action$) {
  return action$.pipe(
    ofType(LOGIN_FAILURE),
    mergeMap(() => {
      openNotificationByType(
        'error',
        'Identification impossible',
        'Veuillez vérifier vos identifiants ou réessayer plus tard',
        3
      );
      return NEVER;
    }),
  );
}

export function accountRequestEpic (action$, state$) {
  return action$.pipe(
    ofType(GET_ACCOUNT_REQUEST),
    mergeMap(({ payload }) =>
      ajax(
        account(payload)
      ).pipe(
        map((res) => {
          const user = res.response;

          if (!user.isInternalAuth) {
            localStorage.removeItem(JWT_STORAGE);
            openNotificationByType(
              'error',
              'Identification impossible',
              "Vous êtes pas autorisé à accéder à l'espace client",
              3
            );
            if (!state$.value.auth.tempPassword) { // Hack to not refresh on login page / Only with token
              setTimeout(() => window.location.reload(true), 3000)
            }
            return getAccountFailure()
          }

          return getAccountSuccess(user)
        }),
        catchError((err) => of(getAccountFailure(err))),
      ),
    ),
  );
}

export function loginSuccessEpic (action$, state$) {
  return action$.pipe(
    ofType(GET_ACCOUNT_SUCCESS),
    mergeMap(() => {
      if (state$.value.auth.mustResetPassword) {
        return of(push('/reset-password'));
      }

      const loggedForbiddenRoutes = ['/signin', '/signup', '/signup/success', '/forgot-password', '/reset-password', '/private-access'];
      if (loggedForbiddenRoutes.includes(state$.value.auth.initURL)) {
        return [
          removePasswordRequest(),
          push('/dashboard'),
        ];
      }

      return [
        removePasswordRequest(),
        push(state$.value.auth.initURL || '/dashboard'),
      ];
    })
  );
}

export function getAccountFailureEpic (action$, state$) {
  return action$.pipe(
    ofType(GET_ACCOUNT_FAILURE),
    mergeMap(() => {
      localStorage.removeItem(JWT_STORAGE);
      return of(push('signin'));
    })
  );
}

export function signupRequestEpic (action$) {
  return action$.pipe(
    ofType(SIGNUP_REQUEST),
    mergeMap(({ payload }) =>
      ajax(
        signup(payload)
      ).pipe(
        map((res) => signupSuccess(res.response)),
        catchError((err) => of(signupFailure(err))),
      ),
    ),
  );
}

export function signupSuccessEpic (action$, state$) {
  return action$.pipe(
    ofType(SIGNUP_SUCCESS),
    mergeMap(() => {
      return of(push('/signup/success'));
    })
  );
}

export function logoutRequestEpic (action$, state$) {
  return action$.pipe(
    ofType(LOGOUT_REQUEST),
    mergeMap(() => {
      localStorage.removeItem(JWT_STORAGE);
      return of(push('signin'));
    })
  );
}

export function updateAccountRequest (payload) {
  return {
    types: [
      UPDATE_ACCOUNT_REQUEST,
      UPDATE_ACCOUNT_SUCCESS,
      UPDATE_ACCOUNT_FAILURE,
      UPDATE_ACCOUNT_END,
    ],
    promise: (getState, dispatch) => ajax(
      updateAccount(payload)
    ).pipe(
      map((res) => {
        const response = res.response;

        openNotificationByType(
          'success',
          'Modification du profil',
          'Les modifications ont été effectuées avec succès.',
          3
        );

        return response;
      }),
      catchError((error) => {
        openNotificationByType(
          'error',
          'Modification du profil',
          "Une erreur s'est produite. Veuillez raffrichir votre page et contacter un administrateur si l'erreur persiste.",
          3
        );
        return Promise.reject(error);
      }),
    ).toPromise(),
  };
}

export function updateNotificationRequest (payload) {
  return {
    types: [
      UPDATE_NOTIFICATION_REQUEST,
      UPDATE_NOTIFICATION_SUCCESS,
      UPDATE_NOTIFICATION_FAILURE,
      UPDATE_NOTIFICATION_END,
    ],
    promise: (getState, dispatch) => ajax(
      updateNotification(payload)
    ).pipe(
      map((res) => {
        const response = res.response;

        openNotificationByType(
          'success',
          'Modification des notifications',
          'Les modifications ont été effectuées avec succès.',
          3
        );

        return response;
      }),
      catchError((error) => {
        openNotificationByType(
          'error',
          'Modification des notifications',
          "Une erreur s'est produite. Veuillez raffrichir votre page et contacter un administrateur si l'erreur persiste.",
          3
        );
        return Promise.reject(error);
      }),
    ).toPromise(),
  };
}

export function forgotPasswordRequest (payload) {
  return {
    types: [
      FORGOT_PASSWORD_REQUEST,
      FORGOT_PASSWORD_SUCCESS,
      FORGOT_PASSWORD_FAILURE,
      FORGOT_PASSWORD_END,
    ],
    promise: (getState, dispatch) => ajax(
      forgotPassword(payload)
    ).pipe(
      map((res) => {
        const response = res.response;

        openNotificationByType(
          'success',
          'Changement de mot de passe',
          'Votre demande a bien été prise en compte, vous allez recevoir un mail pour réinitialiser votre mot de passe.',
          3
        );

        dispatch(push('/signin'));

        return response;
      }),
      catchError((error) => {
        openNotificationByType(
          'error',
          'Changement de mot de passe',
          "Une erreur s'est produite. Veuillez raffrichir votre page et contacter un administrateur si l'erreur persiste.",
          3
        );
        return Promise.reject(error);
      }),
    ).toPromise(),
  };
}

export function resetPasswordRequest (payload) {
  return {
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE,
      RESET_PASSWORD_END,
    ],
    promise: (getState, dispatch) => ajax(
      resetPassword(payload)
    ).pipe(
      map((res) => {
        const response = res.response;

        openNotificationByType(
          'success',
          'Changement de mot de passe',
          'Votre changement de mot de passe a bien été prise en compte',
          3
        );

        dispatch(push('/dashboard'));

        return response;
      }),
      catchError((error) => {
        openNotificationByType(
          'error',
          'Changement de mot de passe',
          "Une erreur s'est produite. Veuillez raffrichir votre page et contacter un administrateur si l'erreur persiste.",
          3
        );
        return Promise.reject(error);
      }),
    ).toPromise(),
  };
}
