import ReactGA from 'react-ga';

export default function gaTrackingMiddleware() {
  if (process.env.REACT_APP_GA_TRACKING_CODE) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
  }
  return ({dispatch, getState}) => {
    return next => action => {
      if (process.env.REACT_APP_GA_TRACKING_CODE && action.type === '@@router/LOCATION_CHANGE') {
        const nextPage = `${action.payload.pathname}${action.payload.search}`;
        ReactGA.pageview(nextPage);
      }
      return next(action);
    }
  }
}