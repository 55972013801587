import React from "react";
import { Form, Row, Col, Spin } from "antd";
import { Link } from "react-router-dom";

import { InputText, Button} from 'views/components/atoms'

import logo from 'assets/images/logo.svg';
import cover from 'assets/images/cover.jpg';
import { connect } from "react-redux";
import { loginRequest } from 'store/ducks/Auth'


class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.login(values);
      }
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;
    const { loading } = this.props;

    return (

      <Row className="arpec-auth-container">
        <Col span={12}>

          <div className="gx-text-center gx-mt-4">
            <Link className="arpec-auth-logo" to="/">
              <img  alt="Logo Arpège" src={logo}/>
            </Link>
          </div>


          <Col span={12} className="arpec-auth-form">

            <Row className="gx-mt-100">
              <Col span={24}>
                <h1 className="arpec-h1">Connexion</h1>
                <p className="gx-text-muted">Merci d’entrer vos informations de connexion pour accéder à votre Espace Client.</p>
              </Col>

              <Col className="gx-mt-4" span={24}>

              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item label="Identifiant" className="gw-w-100">
                  {
                    getFieldDecorator('username', {
                      rules: [
                        {
                          required: true,
                          message: "Merci de saisir votre identifiant",
                        }
                      ],
                    })(<InputText data-cy="username-input"/>)
                  }
                </Form.Item>
                <Form.Item label="Mot de passe" className="gw-w-100">
                  {
                    getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: "Merci de saisir votre mot de passe",
                        }
                      ],
                    })(<InputText type="password" data-cy="password-input"/>)
                  }
                </Form.Item>
                <div className="gx-w-100 gx-text-right gx-mt-3 gx-mb-3">
                  <Link to="/forgot-password">Mot de passe oublié ?</Link>
                </div>
                <Form.Item className="gx-text-center">
                  <Spin spinning={loading}>
                    <Button type="primary" data-cy={"form-submit"} className="gx-w-100" htmlType="submit">
                      Me connecter
                    </Button>
                  </Spin>
                  <p className="gx-mt-2 gx-lh-1">Pas encore de compte ? <Link to="/signup">Demander un compte</Link></p>
                </Form.Item>
              </Form>

              </Col>
            </Row>
            <Row className="gx-login-telemaintenance">
              <Col span={24}>
                <a href="https://get.teamviewer.com/arpege_support" target={"_blank"}>
                    <Button type="default" className="gx-w-100 text-center">
                      Télémaintenance
                    </Button>
                </a>
              </Col>
            </Row>
          </Col>

        </Col>
        <Col className="arpec-auth-cover" span={12}>
            <img src={cover} alt="présentation" />
        </Col>
      </Row>


    )
  }
}

const mapStateToProps = (state, props) => ({
  loading: state.auth.loading,
})

const mapDispatchToProps = dispatch => ({
  login: (payload) => dispatch(loginRequest(payload))
})

const WrappedSignIn = Form.create({ name: 'signIn' })(SignIn);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedSignIn);
