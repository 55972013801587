import React from "react"
import { Upload as UploadAntd, message, Button, Icon } from "antd"

class Upload extends React.Component {

  render() {
    const { label, className } = this.props

    const Fileprops = {
      name: 'file',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      headers: {
        authorization: 'authorization-text',
      },
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };

    return (
        <div className={`arpec-input-container ${className ? className : ''}`}>
            <label className="arpec-input-label">{label}</label>
            <UploadAntd {...Fileprops}>
                <Button>
                    <Icon type="upload" /> Click to Upload
                </Button>
            </UploadAntd>
        </div>
    )
  }
}

export default Upload
