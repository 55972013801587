import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

const InputRadio = ({label, className, items, ...props}) => {
  return (
    <Radio.Group {...props} className="arpec-input-radio">
      {items.map((item, i) => <Radio key={i} value={item.value}>{item.label}</Radio>)}
    </Radio.Group>)
}

InputRadio.propTypes = {
  items: PropTypes.array.isRequired,
  defaultValue: PropTypes.any
};


export default InputRadio;
