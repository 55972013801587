
export function getContracts () {
  return {
    method: 'GET',
    url: '/api/contracts',
    headers: {
      Authorization: true,
    },
  };
}

export function getContract (id) {
  return {
    method: 'GET',
    url: `/api/contracts/${id}`,
    headers: {
      Authorization: true,
    },
  };
}

export function updateContract (id, payload) {

  const body = {
    ...payload
  }

  return {
    method: 'PUT',
    url: `/api/contracts/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true,
    },
    body
  };
}
