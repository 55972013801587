import React from "react";
import { Form, Row, Col } from "antd";
import { Link } from "react-router-dom";

import { InputText, Button } from 'views/components/atoms'

import logo from 'assets/images/logo.svg';
import cover from 'assets/images/cover.jpg';
import {connect} from "react-redux";
import {resetPasswordRequest} from "store/ducks/Auth";

class ResetPassword extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.resetPassword({...values, oldPassword: this.props.oldPassword || values.oldPassword});
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Votre confirmation de mot de passe ne correspond pas au mot de passe défini');
    } else {
      callback();
    }
  }

  render() {
    const { oldPassword } = this.props;
    const {getFieldDecorator} = this.props.form;

    return (

      <Row className="arpec-auth-container">
        <Col span={12}>

          <div className="gx-text-center gx-mt-4">
            <Link className="arpec-auth-logo" to="/">
              <img  alt="Logo Arpège" src={logo}/>
            </Link>
          </div>

          <Col span={12} className="arpec-auth-form">

            <Row className="gx-mt-100">
              <Col span={24}>
                <h1 class="arpec-h1">Réinitialisation de mot de passe</h1>
                <p class="gx-text-muted">Merci de définir votre nouveau mot de passe</p>
              </Col>

              <Col className="gx-mt-4" span={24}>

              <Form onSubmit={this.handleSubmit} className="login-form">
                {
                  !oldPassword && (
                    <Form.Item label="Ancien mot de passe" hasFeedback>
                      {getFieldDecorator('oldPassword', {
                        rules: [{ required: true,
                          message: 'Merci de saisir votre ancien mot de passe', }],
                      })(
                        <InputText type="password" />
                      )}
                    </Form.Item>
                  )
                }
                <Form.Item label="Mot de passe" hasFeedback>
                  {getFieldDecorator('password', {
                    rules: [{ required: true,
                      message: 'Merci de définir votre nouveau mot de passe', }],
                  })(
                    <InputText type="password" />
                  )}
                </Form.Item>
                <Form.Item label="Confirmation du mot de passe" hasFeedback>
                  {getFieldDecorator('confirmPassword', {
                    rules: [{ required: true,
                      message: 'Merci de confirmer le nouveau mot de passe'},
                      {
                        validator: this.compareToFirstPassword
                      }],
                  })(
                    <InputText type="password" />
                  )}
                </Form.Item>

                <Form.Item className="gx-text-center">
                  <Button type="primary" htmlType="submit" className="gx-w-100">
                    Envoyer
                  </Button>
                  <Link to="/signin">
                    <Button type="link" className="gx-mt-4 gx-w-100">
                      Annuler
                    </Button>
                  </Link>
                </Form.Item>
              </Form>

              </Col>
            </Row>

          </Col>

        </Col>
        <Col className="arpec-auth-cover" span={12}>
            <img src={cover} alt="présentation" />
        </Col>
      </Row>


    )
  }
}

const mapStateToProps = (state, props) => ({
  loading: state.auth.loading,
  oldPassword: state.auth.tempPassword
})

const mapDispatchToProps = dispatch => ({
  resetPassword: (payload) => dispatch(resetPasswordRequest(payload))
})

const WrappedResetPassword = Form.create({ name: 'resetPassword' })(ResetPassword);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedResetPassword);
