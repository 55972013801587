import {applyMiddleware, compose, createStore} from "redux";
import reducers, { epic } from "./ducks/index";
import { createEpicMiddleware } from 'redux-observable'
import {routerMiddleware} from "react-router-redux";
import { createHashHistory } from 'history';
import xhook from 'xhook'
import hooks from './utils/hooks'
import gaTrackingMiddleware from './middleware/gaTrackingMiddleware'
import apiMiddleware from './middleware/apiMiddleware'
import actionBlocker from './middleware/actionBlocker'


const history = createHashHistory();
const epicMiddleware = createEpicMiddleware();
const routeMiddleware = routerMiddleware(history);

const middlewares = [routeMiddleware, apiMiddleware(), epicMiddleware, actionBlocker(), gaTrackingMiddleware()];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState,
    composeEnhancers(applyMiddleware(...middlewares)));

  // XHR request middlewares
  if (hooks.before) {
    xhook.before((request) => hooks.before(request, store.getState()))
  }

  if (hooks.after) {
    xhook.after((request, response) => hooks.after(request, response))
  }

  epicMiddleware.run(epic);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./ducks/index', () => {
      const nextRootReducer = require('./ducks/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export {history};
