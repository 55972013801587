import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { logoutRequest } from 'store/ducks/Auth';
import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import { API_URL } from 'util/constants';

import Auxiliary from 'util/Auxiliary';
import UserProfile from './UserProfile';
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from 'constants/ThemeSetting';
import IntlMessages from 'util/IntlMessages';

import dashboard from 'assets/images/icons/dashboard.svg';
import incident from 'assets/images/icons/incident.svg';
import download from 'assets/images/icons/download.svg';
import catalogue from 'assets/images/icons/catalogue.svg';
import saasConsole from 'assets/images/icons/saas-console.svg';
import tokenPack from 'assets/images/icons/token-pack.svg';
import knowledgeBase from 'assets/images/icons/knowledge-base.svg';
import contracts from 'assets/images/icons/contracts.svg';
import contacts from 'assets/images/icons/contacts.svg';
import entities from 'assets/images/icons/entities.svg';
import help from 'assets/images/icons/help.svg';
import manageNotification from 'assets/images/icons/manage-notification.svg';
import user from 'assets/images/icons/profile.svg';
import quotesRequest from 'assets/images/icons/quotes-request.svg';
import disconnect from 'assets/images/icons/disconnect.svg';
import { hasRight } from 'views/hoc/WithCapacity/component';
import { ROLES } from 'constants/Roles';
import { push } from 'react-router-redux';

class SidebarContent extends Component {
  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications';
    }
    return '';
  };

  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup';
    }
    return '';
  };

  render () {
    const { themeType, navStyle, pathname, logout, profile } = this.props;

    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>
      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            <Menu.Item key="dashboard">
              <Link to="/dashboard"><i className="icon"><img src={dashboard} alt=""/></i>
                <IntlMessages id="sidebar.dashboard"/></Link>
            </Menu.Item>
            <Menu.Item key="incidents">
              <Link to="/incidents" data-cy="sidebar-incident"><i className="icon"><img src={incident} alt=""/></i>
                <IntlMessages id="sidebar.incident"/></Link>
            </Menu.Item>
            <Menu.Item key="download" data-cy="sidebar-download" disabled={!hasRight(ROLES.DOWNLOAD, profile)}>
              <Link to="/download"><i className="icon"><img src={download} alt=""/></i>
                <IntlMessages id="sidebar.download"/></Link>
            </Menu.Item>
            {
              hasRight(ROLES.COMFORT_ASSISTANCE, profile) && (
                <Menu.Item key="tasks" data-cy="sidebar-task">
                  <Link to="/tasks"><i className="icon"><img src={tokenPack} alt=""/></i>
                    <IntlMessages id="sidebar.tasks"/></Link>
                </Menu.Item>
              )
            }
            <Menu.Item key="knowledges" data-cy="sidebar-knowledge">
              <Link to="/knowledges"><i className="icon"><img src={knowledgeBase} alt=""/></i>
                <IntlMessages id="sidebar.knowledge-base"/></Link>
            </Menu.Item>
            <Menu.Item key="catalogue">
              <Link to="/catalogue" data-cy="sidebar-catalogue"><i className="icon"><img src={catalogue} alt=""/></i>
                <IntlMessages id="sidebar.catalogue"/></Link>
            </Menu.Item>
            {
              hasRight(ROLES.SAAS, profile) && (
                <Menu.Item key="saas-console">
                  <a target="_blank" href={`${API_URL}/api/token_saas?bearer=${localStorage.getItem('@localStorage/jwt')}`}><i className="icon"><img src={saasConsole} alt=""/></i>
                    <IntlMessages id="sidebar.saas-console"/></a>
                </Menu.Item>
              )
            }
            {
              hasRight(ROLES.ADMIN, profile) && (
                <Menu.Item key="contracts">
                  <Link to="/contracts"><i className="icon"><img src={contracts} alt=""/></i>
                    <IntlMessages id="sidebar.contracts"/></Link>
                </Menu.Item>
              )
            }
            <Menu.Item key="contacts">
                  <Link to="/contacts"><i className="icon"><img src={contacts} alt=""/></i>
                    <IntlMessages id="sidebar.contacts"/></Link>
                </Menu.Item>
          </Menu>
        </CustomScrollbars>
        <div className={`gx-sidebar-user ${this.getNoHeaderClass(navStyle)}`} data-cy="sidebar-user">
          <UserProfile profile={profile}/>
          <div className="gx-second-sidebar">
            <div className="gx-second-sidebar-content">
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                mode="inline">
                <Menu.Item key="profile">
                  <Link to="/profile"><i className="icon"><img src={user} alt=""/></i>
                    <IntlMessages id="sidebar.profile"/></Link>
                </Menu.Item>
                <Menu.Item key="manage/notification">
                  <Link to="/manage/notification"><i className="icon"><img src={manageNotification} alt=""/></i>
                    <IntlMessages id="sidebar.manage.notification"/></Link>
                </Menu.Item>
                <Menu.Item key="entities">
                  <Link to="/entities"><i className="icon"><img src={entities} alt=""/></i>
                    <IntlMessages id="sidebar.entities"/></Link>
                </Menu.Item>
                <Menu.Item key="quotes/request">
                  <Link to="/quotes/request"><i className="icon"><img src={quotesRequest} alt=""/></i>
                    <IntlMessages id="sidebar.quotes.request"/></Link>
                </Menu.Item>
                <Menu.Item key="help">
                  <Link to="/help"><i className="icon"><img src={help} alt=""/></i>
                    <IntlMessages id="sidebar.help"/></Link>
                </Menu.Item>
                <Menu.Item key="disconnect">
                  <Link to="#" onClick={() => logout()}><i className="icon"><img src={disconnect} alt=""/></i>
                    <IntlMessages id="sidebar.disconnect"/></Link>
                </Menu.Item>
              </Menu>
            </div>
          </div>
        </div>
        <Link className={'gx-sidebar-nav gx-flex-row gx-align-items-center gx-justify-content-center gx-lighten-text-white gx-fs-md gx-mt-4'} to="/mentions-legales">Mentions légales</Link>
      </div>
    </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ auth, settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname, profile: auth };
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
