import React, { useEffect } from 'react';
import Topbar from './component';

const TopbarContainer = ({
    history,
    profile,
    width,
    locale,
    getClient,
    cartVisible,
    toggleCartVisible,
    navStyle,
    notificationFetched,
    hasUnReadNotification,
    hasItemShoppingCart
}) => {
    useEffect(() => {
        getClient();
    }, []);

    return <Topbar
      notificationFetched={notificationFetched}
      history={history}
      profile={profile}
      cartVisible={cartVisible}
      toggleCartVisible={toggleCartVisible}
      width={width}
      navStyle={navStyle}
      locale={locale}
      hasUnReadNotification={hasUnReadNotification}
      hasItemShoppingCart={hasItemShoppingCart}
    />;
};

export default TopbarContainer;
