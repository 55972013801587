import React, {Component } from 'react';
import { connect } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { hasJWT, jwtExpired, userJwtID } from 'util/functions';
import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from 'views/pages/Auth/SignIn';
import SignUp from 'views/pages/Auth/SignUp';
import SignupSuccess from 'views/pages/Auth/SignUp/success';
import ForgotPassword from 'views/pages/Auth/ForgotPassword';
import ResetPassword from 'views/pages/Auth/ResetPassword';
import PrivateAccess from 'views/pages/Auth/PrivateAccess';
import { setInitUrl, getAccountRequest } from 'store/ducks/Auth';
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'store/ducks/Setting';
import { getNotificationRequest } from 'store/ducks/Notification';
import loader from 'assets/images/loader.svg';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from 'constants/ThemeSetting';
import {hotjar} from "react-hotjar";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />}
  />;

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount () {
    if (hasJWT() && !jwtExpired()) {
      this.setState({
        loading: true,
      }, () => {
        this.props.getAccountRequest(userJwtID());
        this.props.getNotificationRequest();
      });
    } else {
      this.setState({ loading: false })
    }

    const params = new URLSearchParams(this.props.location.search);

    if (params.has('theme')) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has('nav-style')) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has('layout-type')) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
    if (process.env.REACT_APP_HOTJAR_ID && process.env.REACT_APP_HOTJAR_SV) {
      hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SV);
    }
  }

  componentWillMount () {
    if (this.props.initURL === '') {
      const { history: { location } } = this.props;
      this.props.setInitUrl(location.pathname + location.search);
    }
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  }

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  }

  render () {
    const { match, location, themeType, layoutType, navStyle, locale, authUser, initURL } = this.props;
    const { loading } = this.state;

    if (loading && !authUser) {
      return <div className="loader loading">
        <img src={loader} alt="loader"/>
        <p className="gx-text-center">Connexion en cours</p>
      </div>;
    } else if (loading){
      this.setState({loading: false})
    }

    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }
    if (location.pathname === '/') {
      if (authUser === false) {
        return (<Redirect to={'/signin'}/>);
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return (<Redirect to={'/dashboard'}/>);
      } else {
        return (<Redirect to={initURL}/>);
      }
    }
    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>

          <Switch>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/signup' component={SignUp}/>
            <Route exact path='/signup/success' component={SignupSuccess}/>
            <Route exact path='/forgot-password' component={ForgotPassword}/>
            <Route exact path='/reset-password' component={ResetPassword}/>
            <Route exact path='/private-access' component={PrivateAccess}/>
            <RestrictedRoute path={`${match.url}`} authUser={authUser}
              component={MainApp}/>
          </Switch>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, themeType, layoutType } = settings;
  const { authUser, initURL } = auth;
  return { locale, navStyle, themeType, layoutType, authUser, initURL };
};
export default connect(mapStateToProps, {
  setInitUrl,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange,
  getAccountRequest,
  getNotificationRequest,
})(App);
