import { ajax } from 'rxjs/ajax'
import {map, catchError} from 'rxjs/operators';
import { getContents } from 'store/services/cms'
import { normalize } from 'normalizr';
import { cmsSchema } from "store/schemas";

export const GET_CMS_REQUEST = 'quote/GET_CMS_REQUEST';
export const GET_CMS_SUCCESS = 'quote/GET_CMS_SUCCESS';
export const GET_CMS_FAILURE = 'quote/GET_CMS_FAILURE';
export const GET_CMS_END = 'quote/GET_CMS_END';

const INIT_STATE = {
  fetched: false,
  loading: false,
  list: {}
};


export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_CMS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_CMS_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: {
          ...state.list,
          ...action.result.entities.cms
        }
      }
    case GET_CMS_END:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}


export function getCmsRequest(type) {
  return {
    types: [
      GET_CMS_REQUEST,
      GET_CMS_SUCCESS,
      GET_CMS_FAILURE,
      GET_CMS_END
    ],
    promise: (getState, dispatch) => ajax(
      getContents(type)
    ).pipe(
      map((res) => {
        const response = res.response;

        return normalize(response, cmsSchema)
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

