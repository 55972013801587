import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {/* Dashboard */}
      <Route exact path={`${match.url}dashboard`} component={asyncComponent(() => import('views/pages/Dashboard'))}/>
      <Route exact path={`${match.url}dashboard/preview-ad`} component={asyncComponent(() => import('views/pages/Dashboard/PreviewAd'))}/>
      {/* Task */}
      <Route exact path={`${match.url}tasks`} component={asyncComponent(() => import('views/pages/Tasks/List'))}/>
      <Route exact path={`${match.url}tasks/select`} component={asyncComponent(() => import('views/pages/Tasks/Select'))}/>
      <Route exact path={`${match.url}tasks/declare`} component={asyncComponent(() => import('views/pages/Tasks/Declare'))}/>
      <Route exact path={`${match.url}tasks/:id`} component={asyncComponent(() => import('views/pages/Tasks/Show'))}/>
      {/* Download */}
      <Route exact path={`${match.url}download`} component={asyncComponent(() => import('views/pages/Download/Select'))}/>
      <Route exact path={`${match.url}download/:clientId/:id`} component={asyncComponent(() => import('views/pages/Download/Show'))}/>
      {/* Incident */}
      <Route exact path={`${match.url}incidents`} component={asyncComponent(() => import('views/pages/Incidents/List'))}/>
      <Route exact path={`${match.url}incidents/select`} component={asyncComponent(() => import('views/pages/Incidents/Select'))}/>
      <Route exact path={`${match.url}incidents/declare`} component={asyncComponent(() => import('views/pages/Incidents/Declare'))}/>
      <Route exact path={`${match.url}incidents/:id`} component={asyncComponent(() => import('views/pages/Incidents/Show'))}/>
      {/* Knowledge */}
      <Route exact path={`${match.url}knowledges`} component={asyncComponent(() => import('views/pages/Knowledge/Select'))}/>
      <Route exact path={`${match.url}knowledges/result`} component={asyncComponent(() => import('views/pages/Knowledge/Result'))}/>
      <Route exact path={`${match.url}knowledges/:id`} component={asyncComponent(() => import('views/pages/Knowledge/Show'))}/>
      {/* Catalog */}
      <Route exact path={`${match.url}catalogue`} component={asyncComponent(() => import('views/pages/Catalogue/List'))}/>
      <Route exact path={`${match.url}catalogue/show`} component={asyncComponent(() => import('views/pages/Catalogue/Show'))}/>
      {/* Contract */}
      <Route exact path={`${match.url}contracts`} component={asyncComponent(() => import('views/pages/Contract/List'))}/>
      <Route exact path={`${match.url}contracts/:id`} component={asyncComponent(() => import('views/pages/Contract/Show'))}/>
      {/* Auth */}
      <Route exact path={`${match.url}signin`} component={asyncComponent(() => import('views/pages/Auth/SignIn'))}/>
      <Route exact path={`${match.url}signup`} component={asyncComponent(() => import('views/pages/Auth/SignUp'))}/>
      <Route exact path={`${match.url}signup/success`} component={asyncComponent(() => import('views/pages/Auth/SignUp/success'))}/>
      <Route exact path={`${match.url}forgot-password`} component={asyncComponent(() => import('views/pages/Auth/ForgotPassword'))}/>
      <Route exact path={`${match.url}reset-password`} component={asyncComponent(() => import('views/pages/Auth/ResetPassword'))}/>
      <Route exact path={`${match.url}private-access`} component={asyncComponent(() => import('views/pages/Auth/PrivateAccess'))}/>
      {/* Account */}
      <Route exact path={`${match.url}profile`} component={asyncComponent(() => import('views/pages/Account/Profile'))}/>
      <Route exact path={`${match.url}entities`} component={asyncComponent(() => import('views/pages/Account/Entity'))}/>
      <Route exact path={`${match.url}entities/:id`} component={asyncComponent(() => import('views/pages/Account/Entity/Show'))}/>
      <Route exact path={`${match.url}quotes/request`} component={asyncComponent(() => import('views/pages/Account/QuoteRequests'))}/>
      <Route exact path={`${match.url}manage/notification`} component={asyncComponent(() => import('views/pages/Account/Notifications'))}/>
      <Route exact path={`${match.url}contacts`} component={asyncComponent(() => import('views/pages/Account/Contacts'))}/>
      <Route exact path={`${match.url}help`} component={asyncComponent(() => import('views/pages/Help'))}/>
      {/* Other */}
      <Route exact path={`${match.url}mentions-legales`} component={asyncComponent(() => import('views/pages/Legals'))}/>
      {/* Denied */}
      <Route exact path={`${match.url}access-denied`} component={asyncComponent(() => import('views/pages/Denied'))}/>
      <Route component={asyncComponent(() => import('views/pages/NotFound'))} />
    </Switch>
  </div>
);

export default App;
