import { JWT_STORAGE } from 'store/ducks/Auth';
import { API_URL } from 'util/constants';

const hooks = {
  before: (request) => {
    if (request.url.match(/^\/api/)) {
      request.url = `${API_URL}${request.url}`;
    }
    if (request.headers.Authorization === true) {
      request.headers.Authorization = `Bearer ${localStorage.getItem(JWT_STORAGE)}`;
    }
  },
  after: (request, response) => {
    if (typeof response.data === 'string') {
      try {
        response.data = JSON.parse(response.data);
      } catch (err) {}
    }
  },
};

export default hooks;
