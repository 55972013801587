import { connect } from 'react-redux';
import { sendQuoteRequest, updateItemRequest, deleteItemRequest } from 'store/ducks/Cart'
import { getQuoteRequest } from 'store/ducks/Quote'

const mapStateToProps = (state) => ({
  profile: state.auth,
  cart: state.cart.list,
  loading: state.cart.sending
});

const mapDispatchToProps = (dispatch) => ({
  sendQuote: (payload) => dispatch(sendQuoteRequest(payload)),
  getQuote: () => dispatch(getQuoteRequest()),
  updateItemRequest: (item) => dispatch(updateItemRequest(item)),
  deleteItemRequest: (itemId) => dispatch(deleteItemRequest(itemId))
});

export default connect(mapStateToProps, mapDispatchToProps);
