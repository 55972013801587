import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import { InputNumber, Form, Button, Empty } from "antd";
import { InputTextArea, InputText } from 'views/components/atoms'

import trash from 'assets/images/icons/trash.svg';
import moment from "moment";

const FormCart = ({
  form,
  profile,
  openModalCartSuccess,
  cart,
  updateItemRequest,
  deleteItemRequest,
  sendQuote,
  getQuote,
  loading
}) => {

  const { getFieldDecorator } = form

  const productName = "Pack Jetons 1 - Avec une option pour Concerto opus et Adagio V5"
  const items = Object.values(cart);

  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        sendQuote(values)
        getQuote()
      }
    })
  }

  return (
    <div className="arpec-cart">
      <h2 className="gx-text-center gx-mb-3">Demande de devis</h2>
      <div className="arpec-cart-container">
        {
          items.length > 0 ?
            <Fragment>
              <div className="arpec-cart-items">
              {
                Object.values(cart).map((item) => <div className="arpec-cart-item gx-flex-row gx-align-items-center gx-justify-content-between">
                  <p className="text-blue-0 gx-fs-lg gx-font-weight-bold">{item.article.libelle.length > 50 ? `${item.article.libelle.substring(0, 50)}...` : item.article.libelle}</p>
                  <InputNumber min={1} onChange={(value) => updateItemRequest({
                    ...item,
                    quantity: value,
                    price: item.article.price * value
                  })} defaultValue={item.quantity} />
                  <p className="gx-fs-lg gx-font-weight-bold gx-mx-3 gx-wf-60">{item.price !== 0 && `${item.price} €`}</p>
                  <p onClick={() => deleteItemRequest(item.id)} className="gx-pointer"><i className="icon"><img src={trash} alt=""/></i></p>
                </div>)
              }
              </div>
            <div>
              <Form className="gx-my-3" layout="vertical" onSubmit={handleSubmit}>
                <Form.Item label="Référent" hasFeedback>
                  {getFieldDecorator('referent', {
                    rules: [
                      {
                        required: true,
                        message: 'Merci de saisir votre référent',
                      }
                    ],
                    initialValue: profile.displayName || ''
                  })(<InputText />)}
                </Form.Item>
                <Form.Item label="Contact" hasFeedback>
                  {getFieldDecorator('contact', {
                    rules: [
                      {
                        required: true,
                        message: 'Merci de saisir votre contact',
                      }
                    ],
                    initialValue: profile.phone1 || ''
                  })(<InputText />)}
                </Form.Item>
                <Form.Item label="Commentaire" hasFeedback>
                  {getFieldDecorator('comment', {
                    rules: [
                      {
                        required: false,
                        message: 'Merci de saisir un commentaire',
                      }
                    ],
                    initialValue: ''
                  })(<InputTextArea />)}
                </Form.Item>
                <Button disabled={loading} loading={loading} type="primary" htmlType="submit" block>
                  Envoyer ma demande de devis
                </Button>
              </Form>
            </div>
          </Fragment>
            :
            <div>
              <Empty description={
                <p>Votre panier est vide. <br/>
                  <span className="gx-text-muted">Découvrez notre <Link className="text-blue-2 gx-text-underline" to="/catalogue">catalogue de Produits & Services</Link> et demandez un devis </span>
                </p>
              }/>
            </div>
        }
      </div>
    </div>
  )
}

FormCart.propTypes = {
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  cart: PropTypes.shape.isRequired,
};

const WrappedFormCart = Form.create({ name: 'cart-request-quotes' })(FormCart)
export default WrappedFormCart
