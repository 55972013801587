import React from "react"
import { ConnectedRouter } from "react-router-redux"
import { Provider } from "react-redux"
import { Route, Switch } from "react-router-dom"

import "assets/vendors/style"
import "views/styles/wieldy.less"
import configureStore, { history } from "./store"
import App from "views/layouts/App/index"


export const store = configureStore();

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
    </ConnectedRouter>
  </Provider>;


export default NextApp;
