import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { getContracts, getContract, updateContract } from 'store/services/contract';
import { normalize } from 'normalizr';
import { contractSchema } from 'store/schemas';
import { openNotificationByType } from 'util/Notification';

export const GET_CONTRACTS_REQUEST = 'contract/GET_CONTRACTS_REQUEST';
export const GET_CONTRACTS_SUCCESS = 'contract/GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_FAILURE = 'contract/GET_CONTRACTS_FAILURE';
export const GET_CONTRACTS_END = 'contract/GET_CONTRACTS_END';

export const GET_CONTRACT_REQUEST = 'contract/GET_CONTRACT_REQUEST';
export const GET_CONTRACT_SUCCESS = 'contract/GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_FAILURE = 'contract/GET_CONTRACT_FAILURE';
export const GET_CONTRACT_END = 'contract/GET_CONTRACT_END';

export const UPDATE_CONTRACT_REQUEST = 'contract/UPDATE_CONTRACT_REQUEST';
export const UPDATE_CONTRACT_SUCCESS = 'contract/UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_FAILURE = 'contract/UPDATE_CONTRACT_FAILURE';
export const UPDATE_CONTRACT_END = 'contract/UPDATE_CONTRACT_END';

const INIT_STATE = {
  fetched: false,
  loading: false,
  list: {},
  submitting: {},
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_CONTRACTS_REQUEST:
    case GET_CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CONTRACT_SUCCESS:
    case GET_CONTRACTS_SUCCESS:
    case GET_CONTRACT_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: {
          ...state.list,
          ...action.result.entities.contract,
        },
      };
    case GET_CONTRACTS_END:
    case GET_CONTRACT_END:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CONTRACT_REQUEST:
      return {
        ...state,
        submitting: {
          [action.context]: true
        }
      }
    case UPDATE_CONTRACT_END:
      return {
        ...state,
        submitting: {
          [action.context]: false
        }
      }
    default:
      return state;
  }
}

export function getContractsRequest () {
  return {
    types: [
      GET_CONTRACTS_REQUEST,
      GET_CONTRACTS_SUCCESS,
      GET_CONTRACTS_FAILURE,
      GET_CONTRACTS_END,
    ],
    promise: (getState, dispatch) => ajax(
      getContracts()
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        return normalize(response, [contractSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function getContractRequest (contractId) {
  return {
    types: [
      GET_CONTRACT_REQUEST,
      GET_CONTRACT_SUCCESS,
      GET_CONTRACT_FAILURE,
      GET_CONTRACT_END,
    ],
    promise: () => ajax(
      getContract(contractId)
    ).pipe(
      map(({response}) => {
        return normalize([response], [contractSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function updateContractRequest (id, payload, field) {
  return {
    types: [
      UPDATE_CONTRACT_REQUEST,
      UPDATE_CONTRACT_SUCCESS,
      UPDATE_CONTRACT_FAILURE,
      UPDATE_CONTRACT_END,
    ],
    context: field,
    promise: () => ajax(
      updateContract(id, payload)
    ).pipe(
      map(({ response }) => {

        openNotificationByType(
          'success',
          `Modification du contrat`,
          `Les modifications ont été effectuées avec succès.`,
          3
        );

        return normalize([response], [contractSchema]);
      }),
      catchError((error) => {
        Promise.reject(error)
        openNotificationByType(
          'error',
          'Modification du contrat',
          "Une erreur s'est produite. Veuillez raffrichir votre page et contacter un administrateur si l'erreur persiste.",
          3
        )
      }),
    ).toPromise(),
  };
}
