import {createSelector} from "reselect";

export const isNotificationFetchedSelector = state => state.notification.fetched;
export const notificationListSelector = state => state.notification.list;


export const sortedNotificationList = createSelector(
  isNotificationFetchedSelector,
  notificationListSelector,
  (fetched, list) => {
    if (!fetched) {
      return []
    }
    return Object.values(list).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt));
  }
)

export const hasUnReadNotification = createSelector(
  isNotificationFetchedSelector,
  notificationListSelector,
  (fetched, list) => {
    if (!fetched) {
      return false;
    }

    return Object.values(list).some((notification) => notification.isNotRead);
  }
)
