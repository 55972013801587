import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { getClientSubscribedProducts, getSubscribedProducts } from 'store/services/subscribedProduct';
import { updateClient } from 'store/ducks/Client';
import { normalize } from 'normalizr';
import { subscribedProductSchema } from 'store/schemas';

export const GET_CLIENT_SUBSCRIBED_PRODUCT_REQUEST = 'subscribedProduct/GET_CLIENT_SUBSCRIBED_PRODUCT_REQUEST';
export const GET_CLIENT_SUBSCRIBED_PRODUCT_SUCCESS = 'subscribedProduct/GET_CLIENT_SUBSCRIBED_PRODUCT_SUCCESS';
export const GET_CLIENT_SUBSCRIBED_PRODUCT_FAILURE = 'subscribedProduct/GET_CLIENT_SUBSCRIBED_PRODUCT_FAILURE';
export const GET_CLIENT_SUBSCRIBED_PRODUCT_END = 'subscribedProduct/GET_CLIENT_SUBSCRIBED_PRODUCT_END';

export const GET_SUBSCRIBED_PRODUCT_REQUEST = 'subscribedProduct/GET_SUBSCRIBED_PRODUCT_REQUEST';
export const GET_SUBSCRIBED_PRODUCT_SUCCESS = 'subscribedProduct/GET_SUBSCRIBED_PRODUCT_SUCCESS';
export const GET_SUBSCRIBED_PRODUCT_FAILURE = 'subscribedProduct/GET_SUBSCRIBED_PRODUCT_FAILURE';
export const GET_SUBSCRIBED_PRODUCT_END = 'subscribedProduct/GET_SUBSCRIBED_PRODUCT_END';

const INIT_STATE = {
  fetched: false,
  loading: false,
  list: {},
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_SUBSCRIBED_PRODUCT_REQUEST:
    case GET_CLIENT_SUBSCRIBED_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIBED_PRODUCT_SUCCESS:
    case GET_CLIENT_SUBSCRIBED_PRODUCT_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: {
          ...action.result.entities.subscribedProduct,
        },
      };
    case GET_SUBSCRIBED_PRODUCT_END:
    case GET_CLIENT_SUBSCRIBED_PRODUCT_END:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function getSubscribedProductRequest () {
  return {
    types: [
      GET_SUBSCRIBED_PRODUCT_REQUEST,
      GET_SUBSCRIBED_PRODUCT_SUCCESS,
      GET_SUBSCRIBED_PRODUCT_FAILURE,
      GET_SUBSCRIBED_PRODUCT_END,
    ],
    promise: (getState, dispatch) => ajax(
      getSubscribedProducts()
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];
        const products = Object.values(response.reduce((products, product) => ({
          ...products,
          [product.productLabel]: products[product.productLabel] && products[product.productLabel].recId ? products[product.productLabel] : product,
        }), {}));

        return normalize(products, [subscribedProductSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function getSubscribedProductOfClientRequest (clientId) {
  return {
    types: [
      GET_CLIENT_SUBSCRIBED_PRODUCT_REQUEST,
      GET_CLIENT_SUBSCRIBED_PRODUCT_SUCCESS,
      GET_CLIENT_SUBSCRIBED_PRODUCT_FAILURE,
      GET_CLIENT_SUBSCRIBED_PRODUCT_END,
    ],
    promise: (getState, dispatch) => ajax(
      getClientSubscribedProducts(clientId)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        dispatch(updateClient({
          ...getState().client.list[clientId],
          subscribedProducts: response,
        }));

        return normalize(response, [subscribedProductSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}
