

export function getClientSubscribedProducts(id) {
  return {
    method: 'GET',
    url: `/api/clients/${id}/subscribed_products`,
    headers: {
      Authorization: true
    },
  }
}

export function getSubscribedProducts() {
  return {
    method: 'GET',
    url: `/api/subscribed_products`,
    headers: {
      Authorization: true
    },
  }
}
