
export function getClientTask (clientId) {
  return {
    method: 'GET',
    url: `/api/clients/${clientId}/tasks`,
    headers: {
      Authorization: true,
    },
  };
}

export function getTask (taskId) {
  return {
    method: 'GET',
    url: `/api/tasks/${taskId}`,
    headers: {
      Authorization: true,
    },
  };
}

export function getTaskCategories() {
    return {
      method: 'GET',
      url: `/api/tasks/categories`,
      headers: {
        Authorization: true
      },
    }
  }

export function postTask(payload) {

  let body = new FormData()

  payload.files.forEach((file) => {
    body.append(`files[]`, file)
  })

  body.append('assignmentId', payload.assignmentId || '')
  body.append('emailAddress', payload.email || '')
  body.append('phone', payload.contactPhone || '')
  body.append('askerExternalId', payload.contact || '')
  body.append('product', payload.product || '')
  body.append('actionType', payload.category || '')
  body.append('details', payload.details)
  body.append('clientExternalId', payload.entity || '')

  body.append('typeDemande', payload.type_demande || '')
  body.append('typeDocument', payload.type_document || '')
  body.append('isBlocking', payload.is_blocking || false)


  return {
    method: 'POST',
    url: `/api/tasks/create`,
    headers: {
      Authorization: true
    },
    body
  }
}

export function postTaskAttachment(taskId, file) {

  let body = new FormData()

  body.append(`files[]`, file)

  return {
    method: 'POST',
    url: `/api/tasks/${taskId}/attachment`,
    headers: {
      Authorization: true
    },
    body
  }
}
