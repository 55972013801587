import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import {
  getClientIncidents,
  postIncident,
  updateIncident,
  closeIncident,
  getIncident,
  postIncidentAttachment,
} from 'store/services/incident';
import { updateClient } from 'store/ducks/Client';
import { normalize } from 'normalizr';
import { incidentSchema } from 'store/schemas';
import { openNotificationByType } from 'util/Notification';
import { push } from 'react-router-redux';
import merge from 'lodash/merge';

export const GET_INCIDENTS_REQUEST = 'incident/GET_INCIDENTS_REQUEST';
export const GET_INCIDENTS_SUCCESS = 'incident/GET_INCIDENTS_SUCCESS';
export const GET_INCIDENTS_FAILURE = 'incident/GET_INCIDENTS_FAILURE';
export const GET_INCIDENTS_END = 'incident/GET_INCIDENTS_END';

export const GET_INCIDENT_REQUEST = 'incident/GET_INCIDENT_REQUEST';
export const GET_INCIDENT_SUCCESS = 'incident/GET_INCIDENT_SUCCESS';
export const GET_INCIDENT_FAILURE = 'incident/GET_INCIDENT_FAILURE';
export const GET_INCIDENT_END = 'incident/GET_INCIDENT_END';

export const CLOSE_INCIDENT_REQUEST = 'incident/CLOSE_INCIDENT_REQUEST';
export const CLOSE_INCIDENT_SUCCESS = 'incident/CLOSE_INCIDENT_SUCCESS';
export const CLOSE_INCIDENT_FAILURE = 'incident/CLOSE_INCIDENT_FAILURE';
export const CLOSE_INCIDENT_END = 'incident/CLOSE_INCIDENT_END';

export const POST_INCIDENT_REQUEST = 'incident/POST_INCIDENT_REQUEST';
export const POST_INCIDENT_SUCCESS = 'incident/POST_INCIDENT_SUCCESS';
export const POST_INCIDENT_FAILURE = 'incident/POST_INCIDENT_FAILURE';
export const POST_INCIDENT_END = 'incident/POST_INCIDENT_END';

export const POST_INCIDENT_ATTACHMENT_REQUEST = 'incident/POST_INCIDENT_ATTACHMENT_REQUEST';
export const POST_INCIDENT_ATTACHMENT_SUCCESS = 'incident/POST_INCIDENT_ATTACHMENT_SUCCESS';
export const POST_INCIDENT_ATTACHMENT_FAILURE = 'incident/POST_INCIDENT_ATTACHMENT_FAILURE';
export const POST_INCIDENT_ATTACHMENT_END = 'incident/POST_INCIDENT_ATTACHMENT_END';

export const UPDATE_INCIDENT_REQUEST = 'incident/UPDATE_INCIDENT_REQUEST';
export const UPDATE_INCIDENT_SUCCESS = 'incident/UPDATE_INCIDENT_SUCCESS';
export const UPDATE_INCIDENT_FAILURE = 'incident/UPDATE_INCIDENT_FAILURE';
export const UPDATE_INCIDENT_END = 'incident/UPDATE_INCIDENT_END';

export const UPDATE_INCIDENT = 'incident/UPDATE_INCIDENT';

const INIT_STATE = {
  fetched: [],
  loading: false,
  closing: false,
  submitting: false,
  list: {},
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case CLOSE_INCIDENT_REQUEST:
      return {
        ...state,
        closing: true
      };
    case GET_INCIDENTS_REQUEST:
      return {
        ...state,
        loading: true,
        list: {},
      };
    case GET_INCIDENT_REQUEST:
    case UPDATE_INCIDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_INCIDENT:
    case GET_INCIDENT_SUCCESS:
    case POST_INCIDENT_SUCCESS:
    case UPDATE_INCIDENT_SUCCESS:
    case GET_INCIDENTS_SUCCESS:
    case POST_INCIDENT_ATTACHMENT_SUCCESS:
    case CLOSE_INCIDENT_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: merge({}, state.list, action.result.entities.incident),
      };
    case GET_INCIDENT_END:
    case GET_INCIDENTS_END:
    case UPDATE_INCIDENT_END:
      return {
        ...state,
        loading: false,
      };
    case POST_INCIDENT_ATTACHMENT_REQUEST:
    case POST_INCIDENT_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case POST_INCIDENT_ATTACHMENT_END:
    case POST_INCIDENT_END:
      return {
        ...state,
        submitting: false,
      };
    case CLOSE_INCIDENT_END:
      return {
        ...state,
        closing: false,
      };
    default:
      return state;
  }
}

export function updateIncidentAction (incident) {
  return {
    type: UPDATE_INCIDENT,
    result: normalize(incident, incidentSchema),
  };
}

export function getIncidentOfClientRequest (clientId, search, filter, limit) {
  return {
    types: [
      GET_INCIDENTS_REQUEST,
      GET_INCIDENTS_SUCCESS,
      GET_INCIDENTS_FAILURE,
      GET_INCIDENTS_END,
    ],
    promise: (getState, dispatch) => ajax(
      getClientIncidents(clientId, search, filter, limit)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        dispatch(updateClient({
          ...getState().client.list[clientId],
          incidents: response,
        }));

        return {
          ...normalize(response, [incidentSchema]),
          fetched: clientId,
        };
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function postIncidentRequest (payload) {
  return {
    types: [
      POST_INCIDENT_REQUEST,
      POST_INCIDENT_SUCCESS,
      POST_INCIDENT_FAILURE,
      POST_INCIDENT_END,
    ],
    promise: (getState, dispatch) => ajax(
      postIncident(payload)
    ).pipe(
      map((res) => {
        const response = res.response;

        dispatch(updateClient({
          ...getState().client.list[payload.entity],
          incidents: [
            ...getState().client.list[payload.entity].incidents,
            response,
          ],
        }));

        openNotificationByType(
          'success',
          'Déclaration d\'incident',
          'L\'incident à été crée avec succès',
          5
        );

        payload.files.forEach((file) => {
          dispatch(postIncidentAttachmentRequest(response.id, file, false));
        })

        dispatch(push('/incidents'));

        return normalize([response], [incidentSchema]);
      }),
      catchError((error) => {
        openNotificationByType(
          'error',
          "Une erreur s'est produite",
          "Veuillez contacter un administrateur pour plus d'information",
          5
        );
        return Promise.reject(error);
      }),
    ).toPromise(),
  };
}

export function updateIncidentRequest (incidentId, payload) {
  return {
    types: [
      UPDATE_INCIDENT_REQUEST,
      UPDATE_INCIDENT_SUCCESS,
      UPDATE_INCIDENT_FAILURE,
      UPDATE_INCIDENT_END,
    ],
    promise: (getState, dispatch) => ajax(
      updateIncident(incidentId, payload)
    ).pipe(
      map((res) => {
        const response = res.response;

        openNotificationByType(
          'success',
          'Modification de l\'incident',
          'L\'incident a été modifié avec succès',
          5
        );

        dispatch(push(`/incidents/${incidentId}`));
        return normalize([response], [incidentSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function closeIncidentRequest(incidentId, payload) {
  return {
    types: [
      CLOSE_INCIDENT_REQUEST,
      CLOSE_INCIDENT_SUCCESS,
      CLOSE_INCIDENT_FAILURE,
      CLOSE_INCIDENT_END,
    ],
    promise: (getState, dispatch) => ajax(
      closeIncident(incidentId)
    ).pipe(
      map((res) => {
        const response = res.response;
        openNotificationByType(
          'success',
          'Annulation de l\'incident',
          'L\'incident a été cloturé avec succès',
          5
        );
console.log(normalize([response], [incidentSchema]));
        return normalize([response], [incidentSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function postIncidentAttachmentRequest (incidentId, file, notification = true) {
  return {
    types: [
      POST_INCIDENT_ATTACHMENT_REQUEST,
      POST_INCIDENT_ATTACHMENT_SUCCESS,
      POST_INCIDENT_ATTACHMENT_FAILURE,
      POST_INCIDENT_ATTACHMENT_END,
    ],
    promise: (getState, dispatch) => ajax(
      postIncidentAttachment(incidentId, file)
    ).pipe(
      map((res) => {
        const files = res.response;

        if (notification) {
          openNotificationByType(
            'success',
            'Ajout de la pièce jointe',
            'La pièce jointe à été ajoutée avec succès',
            5
          );
        }

        const response = {
          ...getState().incident.list[incidentId],
          files,
        };

        return normalize([response], [incidentSchema]);
      }),
      catchError((error) => {
        openNotificationByType(
          'error',
          "Une erreur s'est produite",
          "Veuillez contacter un administrateur pour plus d'information",
          5
        );
        return Promise.reject(error);
      }),
    ).toPromise(),
  };
}

export function getIncidentRequest (incidentId) {
  return {
    types: [
      GET_INCIDENT_REQUEST,
      GET_INCIDENT_SUCCESS,
      GET_INCIDENT_FAILURE,
      GET_INCIDENT_END,
    ],
    promise: (getState, dispatch) => ajax(
      getIncident(incidentId)
    ).pipe(
      map((res) => {
        const response = res.response;

        response.complete = true;

        return normalize([response], [incidentSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}
