
export const ROLES = {
    ADMIN: 'isAdmin',
    INCIDENT: 'hasIncidentAccess',
    DOWNLOAD: 'hasDownloadAccess',
    COMFORT_ASSISTANCE: 'hasComfortAssistAcces',
    SAAS: 'hasSaasAccess',
    ELECTION: 'hasElections',
    CHILDHOOD: 'hasEnfance',
    POPULATION: 'hasPopulation',
    SOCIAL: 'hasSocial',
    E_SERVICES: 'hasEServices',
    COMPUTER_SCIENCE: 'hasInformatique',
}