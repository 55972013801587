import React from "react";
import NotificationItem from "./NotificationItem";

const AppNotification = ({loadingIds, readNotifications, notifications}) => {

  const globalLoading = loadingIds.length === notifications.length;

  const hasUnreadNotification = notifications.some((notification) => notification.isNotRead);

  return (
    <div className="arpec-notification-inner">
      <div className={`arpec-notification-header ${!hasUnreadNotification && ('arpec-notification-header-disabled')}`}>
        <p className="gx-mb-0" onClick={() => !globalLoading && hasUnreadNotification ?
          readNotifications(notifications.map((notification) => notification.id)) : undefined
        }>
          Marquer tout comme lu
        </p>
      </div>
      <ul className="gx-sub-popover">
        {notifications.map((notification, index) =>
          <NotificationItem loadingIds={loadingIds} readNotifications={readNotifications} key={index} notification={notification}/>
        )}
      </ul>
    </div>
  )
};

export default AppNotification;

