import React from "react"
import { Input } from "antd";

const { TextArea } = Input;

const InputTextArea = React.forwardRef(({rows = 8, className = '', ...props}, ref) => (
  <TextArea className={className} rows={rows} {...props} ref={ref}/>
))



export default InputTextArea
