import React, { useEffect } from 'react';
import FormCart from './component';

const FormCartContainer = ({
  profile,
  openModalCartSuccess,
  cart,
  updateItemRequest,
  deleteItemRequest,
  sendQuote,
  loading
}) => {

  return <FormCart
    openModalCartSuccess={openModalCartSuccess}
    profile={profile}
    cart={cart}
    loading={loading}
    sendQuote={sendQuote}
    updateItemRequest={updateItemRequest}
    deleteItemRequest={deleteItemRequest}
  />;
};

export default FormCartContainer;
