import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as settings } from './Setting';
import { reducer as authReducer, LOGOUT_REQUEST, epic as authEpic } from './Auth';
import { reducer as cartReducer, epic as cartEpic } from './Cart';
import { reducer as client } from './Client';
import { reducer as incident } from './Incident';
import { reducer as subscribedProduct } from './SubscribedProduct';
import { reducer as contact } from './Contact';
import { reducer as panel } from './Panel';
import { reducer as product } from './Product';
import { reducer as download } from './Download';
import { reducer as knowledge } from './Knowledge';
import { reducer as task } from './Task';
import { reducer as notification, epic as notificationEpic } from './Notification';
import { reducer as exchange } from './Exchange';
import { reducer as article } from './Article';
import { reducer as quote } from './Quote';
import { reducer as contract } from './Contract';
import { reducer as cms } from './Cms';
import { reducer as ad } from './Ad';
import { reducer as token } from './Token';

const reducers = combineReducers({
  ad,
  auth: authReducer,
  cart: cartReducer,
  contact,
  contract,
  client,
  download,
  exchange,
  knowledge,
  incident,
  panel,
  routing,
  settings,
  subscribedProduct,
  task,
  notification,
  product,
  article,
  quote,
  cms,
  token,
});

export const epic = combineEpics(
  authEpic,
  cartEpic,
  notificationEpic
);

// Reset the entire state except routing and parameters
export default (state, action) => {
  if (action.type === LOGOUT_REQUEST) {
    const { routing, settings } = state;
    return reducers({ routing, settings }, action);
  }
  return reducers(state, action);
};
