
export function getAds (isDraft = false) {
  const url = `/api/adverts${isDraft ? '?draft=true' : ''}`;
  return {
    method: 'GET',
    url,
    headers: {
      Authorization: true,
    },
  };
}
