import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { getClientProducts, getProduct } from 'store/services/product';
import { updateClient } from 'store/ducks/Client';
import { normalize } from 'normalizr';
import { productSchema } from 'store/schemas';
import merge from 'lodash/merge';

export const GET_CLIENT_PRODUCT_REQUEST = 'product/GET_CLIENT_PRODUCT_REQUEST';
export const GET_CLIENT_PRODUCT_SUCCESS = 'product/GET_CLIENT_PRODUCT_SUCCESS';
export const GET_CLIENT_PRODUCT_FAILURE = 'product/GET_CLIENT_PRODUCT_FAILURE';
export const GET_CLIENT_PRODUCT_END = 'product/GET_CLIENT_PRODUCT_END';

export const GET_PRODUCT_REQUEST = 'product/GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'product/GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'product/GET_PRODUCT_FAILURE';
export const GET_PRODUCT_END = 'product/GET_PRODUCT_END';

export const UPDATE_PRODUCT = 'product/UPDATE_PRODUCT';
export const SELECT_PRODUCT = 'product/SELECT_PRODUCT';

const INIT_STATE = {
  fetched: [],
  loading: false,
  selected: null,
  list: {},
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_CLIENT_PRODUCT_REQUEST:
    case GET_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRODUCT:
    case GET_CLIENT_PRODUCT_SUCCESS:
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: merge({}, state.list, action.result.entities.product),
      };
    case GET_CLIENT_PRODUCT_END:
    case GET_PRODUCT_END:
      return {
        ...state,
        loading: false,
      };
    case SELECT_PRODUCT:
      return {
        ...state,
        selected: action.result,
      };
    default:
      return state;
  }
}

export function selectProduct (productId) {
  return {
    type: SELECT_PRODUCT,
    result: productId,
  };
}

export function updateProduct (product) {
  return {
    type: UPDATE_PRODUCT,
    result: normalize(product, productSchema),
  };
}

export function getProductRequest (productId) {
  return {
    types: [
      GET_PRODUCT_REQUEST,
      GET_PRODUCT_SUCCESS,
      GET_PRODUCT_FAILURE,
      GET_PRODUCT_END,
    ],
    promise: () => ajax(
      getProduct(productId)
    ).pipe(
      map(({ response }) => {
        response.complete = true;
        return normalize([response], [productSchema]);
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}


export function getProductOfClientRequest (clientId) {
  return {
    types: [
      GET_CLIENT_PRODUCT_REQUEST,
      GET_CLIENT_PRODUCT_SUCCESS,
      GET_CLIENT_PRODUCT_FAILURE,
      GET_CLIENT_PRODUCT_END,
    ],
    promise: (getState, dispatch) => ajax(
      getClientProducts(clientId)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        dispatch(updateClient({
          ...getState().client.list[clientId],
          products: response,
        }));

        return {
          ...normalize(response, [productSchema]),
          fetched: clientId,
        };
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}
