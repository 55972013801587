import { ajax } from 'rxjs/ajax'
import {map, catchError} from 'rxjs/operators';
import { getKnowledge, getKnowledgesMostViewed, getKnowledges, getKnowledgeAttachment } from 'store/services/knowledge'
import { normalize } from 'normalizr';
import { knowledgeSchema } from "store/schemas";

export const GET_KNOWLEDGES_REQUEST = 'knowledge/GET_KNOWLEDGES_REQUEST';
export const GET_KNOWLEDGES_SUCCESS = 'knowledge/GET_KNOWLEDGES_SUCCESS';
export const GET_KNOWLEDGES_FAILURE = 'knowledge/GET_KNOWLEDGES_FAILURE';
export const GET_KNOWLEDGES_END = 'knowledge/GET_KNOWLEDGES_END';

export const GET_KNOWLEDGES_MOSTVIEWED_REQUEST = 'knowledge/GET_KNOWLEDGES_MOSTVIEWED_REQUEST';
export const GET_KNOWLEDGES_MOSTVIEWED_SUCCESS = 'knowledge/GET_KNOWLEDGES_MOSTVIEWED_SUCCESS';
export const GET_KNOWLEDGES_MOSTVIEWED_FAILURE = 'knowledge/GET_KNOWLEDGES_MOSTVIEWED_FAILURE';
export const GET_KNOWLEDGES_MOSTVIEWED_END = 'knowledge/GET_KNOWLEDGES_MOSTVIEWED_END';

export const GET_KNOWLEDGE_REQUEST = 'knowledge/GET_KNOWLEDGE_REQUEST';
export const GET_KNOWLEDGE_SUCCESS = 'knowledge/GET_KNOWLEDGE_SUCCESS';
export const GET_KNOWLEDGE_FAILURE = 'knowledge/GET_KNOWLEDGE_FAILURE';
export const GET_KNOWLEDGE_END = 'knowledge/GET_KNOWLEDGE_END';

const INIT_STATE = {
  fetched: [],
  loading: false,
  list: {},
  categories : [],
  mostviewed: {}
};


export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_KNOWLEDGES_REQUEST:
    case GET_KNOWLEDGE_REQUEST:
    case GET_KNOWLEDGES_MOSTVIEWED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_KNOWLEDGE_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: {
          ...action.result.entities.knowledge
        }
      }
    case GET_KNOWLEDGES_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: {
          ...action.result.entities.knowledge.undefined['hydra:member']
        }
      }
    case GET_KNOWLEDGES_MOSTVIEWED_SUCCESS:
      return {
        ...state,
        mostviewed: {
          ...action.result.entities.knowledge.undefined
        }
      }
    case GET_KNOWLEDGES_END:
    case GET_KNOWLEDGE_END:
    case GET_KNOWLEDGES_MOSTVIEWED_END:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}

export function getKnowledgeRequest(knowledgeId) {
  return {
    types: [
      GET_KNOWLEDGE_REQUEST,
      GET_KNOWLEDGE_SUCCESS,
      GET_KNOWLEDGE_FAILURE,
      GET_KNOWLEDGE_END
    ],
    promise: (getState, dispatch) => ajax(
      getKnowledge(knowledgeId)
    ).pipe(
      map((res) => {
        const response = res.response;

        return normalize([response], [knowledgeSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function getKnowledgesRequest(search, filter) {
  return {
    types: [
      GET_KNOWLEDGES_REQUEST,
      GET_KNOWLEDGES_SUCCESS,
      GET_KNOWLEDGES_FAILURE,
      GET_KNOWLEDGES_END
    ],
    promise: (getState, dispatch) => ajax(
      getKnowledges(search, filter)
    ).pipe(
      map((res) => {
        const response = res.response

        return normalize([response], [knowledgeSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function getKnowledgesMostViewedRequest() {
  return {
    types: [
      GET_KNOWLEDGES_MOSTVIEWED_REQUEST,
      GET_KNOWLEDGES_MOSTVIEWED_SUCCESS,
      GET_KNOWLEDGES_MOSTVIEWED_FAILURE,
      GET_KNOWLEDGES_MOSTVIEWED_END
    ],
    promise: (getState, dispatch) => ajax(
      getKnowledgesMostViewed()
    ).pipe(
      map((res) => {
        const response = res.response

        return normalize([response], [knowledgeSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}
