import { isObservable } from "rxjs";

export default function apiMiddleware() {
  return ({ dispatch, getState }) => {
    return next =>  action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      if(Array.isArray(action)) {
        return action.forEach((a) => {
          next(a)
        })
      }

      if(isObservable(action)){
        return action(dispatch, getState);
      }

      const { promise, types, context, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }
      const [REQUEST, SUCCESS, FAILURE, END] = types;
      next({ ...rest, type: REQUEST, context });
      return promise(getState, dispatch)
        .then(
          result => next({ ...rest, result, type: SUCCESS, context }),
          error => next({ ...rest, error, type: FAILURE, context }),
        )
        .catch(error => {
          console.error('MIDDLEWARE ERROR:', error);
          next({ ...rest, error, type: FAILURE, context });
        })
        .finally(() => {
          if (END) {
            return next({type: END, context})
          }
        });
    };
  };
}
