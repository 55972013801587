

export function sendQuote(payload, state) {

  const data = {
    ...payload,
    claimantEmail: state.auth.primaryEmail || '',
    claimantDisplayName: state.auth.displayName || '',
    claimantPhone: state.auth.phone1 || state.auth.phone2 || '',
    clientCommerceCC: state.client.list[state.auth.clientId].commerceCC || '',
    clientCommerceCCMail: state.client.list[state.auth.clientId].commerceCCMail || '',
    clientCommerceCCTel: state.client.list[state.auth.clientId].commerceCCTel || '',
    items: {
      ...Object.values(state.cart.list).map((item) => ({
          ...item,
          client: state.client.list[item.client],
          product: state.product.list[item.product],
          article: item.article,
          catalogueCategory: state.article.categories[item.article.catalogueCategory]
        })
      )
    }
  }

  return {
    method: 'POST',
    url: `/api/quote/create`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    },
    body: data
  }
}

export function getQuotes() {
  return {
    method: 'GET',
    url: `/api/quotes`,
    headers: {
      Authorization: true
    },
  }
}
