import { ajax } from 'rxjs/ajax'
import {map, catchError} from 'rxjs/operators';
import { getQuotes } from 'store/services/quote'
import { normalize } from 'normalizr';
import { quoteSchema } from "store/schemas";

export const GET_QUOTE_REQUEST = 'quote/GET_QUOTE_REQUEST';
export const GET_QUOTE_SUCCESS = 'quote/GET_QUOTE_SUCCESS';
export const GET_QUOTE_FAILURE = 'quote/GET_QUOTE_FAILURE';
export const GET_QUOTE_END = 'quote/GET_QUOTE_END';

export const SELECT_QUOTE = 'quote/SELECT_QUOTE';
export const UPDATE_QUOTE = 'quote/UPDATE_QUOTE';


const INIT_STATE = {
  fetched: false,
  loading: false,
  selected: null,
  list: {}
};


export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_QUOTE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UPDATE_QUOTE:
    case GET_QUOTE_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: {
          ...state.list,
          ...action.result.entities.quote
        }
      }
    case GET_QUOTE_END:
      return {
        ...state,
        loading: false
      }
    case SELECT_QUOTE:
      return {
        ...state,
        selected: action.result
      }
    default:
      return state;
  }
}


export function getQuoteRequest() {
  return {
    types: [
      GET_QUOTE_REQUEST,
      GET_QUOTE_SUCCESS,
      GET_QUOTE_FAILURE,
      GET_QUOTE_END
    ],
    promise: (getState, dispatch) => ajax(
      getQuotes()
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        return normalize(response, [quoteSchema])
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function updateQuote (quote) {
  return {
    type: UPDATE_QUOTE,
    result: normalize(quote, quoteSchema)
  }
}

export function selectQuote(quoteId) {
  return {
    type: SELECT_QUOTE,
    result: quoteId
  }
}
