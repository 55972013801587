import React from 'react';
import AppNotification from './component';
import {Empty} from "antd";

const AppNotificationContainer = ({
  notifications,
  readNotifications,
  loadingIds
}) => {

  if (notifications.length === 0) {
    return (
      <div className="arpec-notification-empty">
        <Empty description={
          <p>Vous avez aucune notification</p>
        }/>
      </div>
    )
  }

  return <AppNotification
    readNotifications={readNotifications}
    notifications={notifications}
    loadingIds={loadingIds}
  />;
};

export default AppNotificationContainer;
