import React from 'react';
import { Avatar, Icon } from 'antd';

const UserProfile = ({ profile }) => {
  return (
    <div className="gx-flex-row gx-align-items-center gx-justify-content-center gx-avatar-row">
      { profile.avatar
        ? <Avatar src={profile.avatar} size={40} className="gx-pointer gx-mr-3" alt=""/>
        : (
          <Avatar size={40} className="gx-pointer gx-mr-3" alt=""/>
        )}
      <span className="gx-avatar-name">{profile.displayName || ''} <Icon type="caret-right" /></span>
    </div>

  );
};

export default UserProfile;
