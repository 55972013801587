import React from "react"
import { Icon, Tooltip } from 'antd'
import IntlMessages from "util/IntlMessages";
import { getStatusColor, getStatusDescription } from "util/functions";

const Status = ({ status }) => {
    return (
        <span className={`arpec-status text-${getStatusColor(status)}`}>
            <IntlMessages id={`app.incident.status.${status.replace(/ /gi, '.').toLowerCase()}`} />
            <Tooltip placement="top" title={(
                <div className="gx-text-center">
                    <span>
                        {getStatusDescription(status)}
                    </span>
                </div>
            )}>
                <Icon type="info-circle" />
            </Tooltip>
        </span>
    )
}

export default Status


