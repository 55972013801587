
import { ajax } from 'rxjs/ajax';
import { map, catchError } from 'rxjs/operators';
import { getTaskExchanges, getIncidentExchanges, postIncidentNote, postTaskNote } from 'store/services/exchanges';
import { normalize } from 'normalizr';
import { exchangeSchema } from 'store/schemas';
import { updateIncidentAction } from 'store/ducks/Incident';
import { updateTask } from 'store/ducks/Task';
import { openNotificationByType } from 'util/Notification';
import merge from 'lodash/merge';

export const GET_TASK_EXCHANGE_REQUEST = 'task/GET_TASK_EXCHANGE_REQUEST';
export const GET_TASK_EXCHANGE_SUCCESS = 'task/GET_TASK_EXCHANGE_SUCCESS';
export const GET_TASK_EXCHANGE_FAILURE = 'task/GET_TASK_EXCHANGE_FAILURE';
export const GET_TASK_EXCHANGE_END = 'task/GET_TASK_EXCHANGE_END';

export const GET_INCIDENT_EXCHANGE_REQUEST = 'task/GET_INCIDENT_EXCHANGE_REQUEST';
export const GET_INCIDENT_EXCHANGE_SUCCESS = 'task/GET_INCIDENT_EXCHANGE_SUCCESS';
export const GET_INCIDENT_EXCHANGE_FAILURE = 'task/GET_INCIDENT_EXCHANGE_FAILURE';
export const GET_INCIDENT_EXCHANGE_END = 'task/GET_INCIDENT_EXCHANGE_END';

export const POST_INCIDENT_NOTE_REQUEST = 'task/POST_INCIDENT_NOTE_REQUEST';
export const POST_INCIDENT_NOTE_SUCCESS = 'task/POST_INCIDENT_NOTE_SUCCESS';
export const POST_INCIDENT_NOTE_FAILURE = 'task/POST_INCIDENT_NOTE_FAILURE';
export const POST_INCIDENT_NOTE_END = 'task/POST_INCIDENT_NOTE_END';

export const POST_TASK_NOTE_REQUEST = 'task/POST_TASK_NOTE_REQUEST';
export const POST_TASK_NOTE_SUCCESS = 'task/POST_TASK_NOTE_SUCCESS';
export const POST_TASK_NOTE_FAILURE = 'task/POST_TASK_NOTE_FAILURE';
export const POST_TASK_NOTE_END = 'task/POST_TASK_NOTE_END';

const INIT_STATE = {
  fetchedTask: [],
  fetchedIncident: [],
  loading: false,
  submitting: false,
  submitted: false,
  list: {},
};

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_INCIDENT_EXCHANGE_REQUEST:
    case GET_TASK_EXCHANGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TASK_EXCHANGE_SUCCESS:
      return {
        ...state,
        fetchedTask: [...new Set([...state.fetchedTask, action.result.fetched])],
        list: merge({}, state.list, action.result.entities.exchange),
      };
    case GET_INCIDENT_EXCHANGE_SUCCESS:
      return {
        ...state,
        fetchedIncident: [...new Set([...state.fetchedIncident, action.result.fetched])],
        list: merge({}, state.list, action.result.entities.exchange),
      };
    case POST_INCIDENT_NOTE_SUCCESS:
      return {
        ...state,
        fetchedIncident: [...new Set([...state.fetchedIncident, action.result.fetched])],
        list: merge({}, state.list, action.result.entities.exchange),
        submitted: true
      };
    case POST_TASK_NOTE_SUCCESS:
      return {
        ...state,
        fetchedTask: [...new Set([...state.fetchedTask, action.result.fetched])],
        list: merge({}, state.list, action.result.entities.exchange),
        submitted: true
      };
    case GET_INCIDENT_EXCHANGE_END:
    case GET_TASK_EXCHANGE_END:
      return {
        ...state,
        loading: false,
      };
    case POST_TASK_NOTE_REQUEST:
    case POST_INCIDENT_NOTE_REQUEST: {
      return {
        ...state,
        submitting: true,
        submitted: false
      }
    }
    case POST_TASK_NOTE_END:
    case POST_INCIDENT_NOTE_END: {
      return {
        ...state,
        submitting: false,
        submitted: false
      }
    }
    default:
      return state;
  }
}

export function getTaskExchangesRequest (taskId) {
  return {
    types: [
      GET_TASK_EXCHANGE_REQUEST,
      GET_TASK_EXCHANGE_SUCCESS,
      GET_TASK_EXCHANGE_FAILURE,
      GET_TASK_EXCHANGE_END,
    ],
    promise: (getState, dispatch) => ajax(
      getTaskExchanges(taskId)
    ).pipe(
      map((res) => {
        const exchanges = res.response['hydra:member'];

        dispatch(updateTask({
          ...getState().task.list[taskId],
          exchanges,
        }));

        return {
          ...normalize(exchanges, [exchangeSchema]),
          fetched: taskId,
        };
      }),

      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function getIncidentExchangesRequest (incidentId) {
  return {
    types: [
      GET_INCIDENT_EXCHANGE_REQUEST,
      GET_INCIDENT_EXCHANGE_SUCCESS,
      GET_INCIDENT_EXCHANGE_FAILURE,
      GET_INCIDENT_EXCHANGE_END,
    ],
    promise: (getState, dispatch) => ajax(
      getIncidentExchanges(incidentId)
    ).pipe(
      map((res) => {
        const exchanges = res.response['hydra:member'];

        dispatch(updateIncidentAction({
          ...getState().incident.list[incidentId],
          exchanges,
        }));

        return {
          ...normalize(exchanges, [exchangeSchema]),
          fetched: incidentId,
        };
      }),

      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function postIncidentNoteRequest (incidentId, payload) {
  return {
    types: [
      POST_INCIDENT_NOTE_REQUEST,
      POST_INCIDENT_NOTE_SUCCESS,
      POST_INCIDENT_NOTE_FAILURE,
      POST_INCIDENT_NOTE_END,
    ],
    promise: (getState, dispatch) => ajax(
      postIncidentNote(incidentId, payload)
    ).pipe(
      map((res) => {

        const response = res.response

        dispatch(updateIncidentAction({
          ...getState().incident.list[incidentId],
          exchanges: [
            ...getState().incident.list[incidentId].exchanges,
            response
          ]
        }));

        openNotificationByType(
          'success',
          `Création de la note`,
          `La note a été créée avec succès`,
          3
        );

        return {
          ...normalize([response], [exchangeSchema]),
          fetched: incidentId
        }
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}

export function postTaskNoteRequest (taskId, payload) {
  return {
    types: [
      POST_TASK_NOTE_REQUEST,
      POST_TASK_NOTE_SUCCESS,
      POST_TASK_NOTE_FAILURE,
      POST_TASK_NOTE_END,
    ],
    promise: (getState, dispatch) => ajax(
      postTaskNote(taskId, payload)
    ).pipe(
      map((res) => {

        const response = res.response

        dispatch(updateTask({
          ...getState().task.list[taskId],
          exchanges: [
            ...getState().task.list[taskId].exchanges,
            response
          ]
        }));

        openNotificationByType(
          'success',
          `Création de la note`,
          `La note a été créée avec succès`,
          3
        );

        return {
          ...normalize([response], [exchangeSchema]),
          fetched: taskId
        }
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise(),
  };
}
