

export const login = (payload) => {

  const body = {
    loginId: payload.username,
    password: payload.password
  }

  return {
    method: 'POST',
    url: `/api/login_check`,
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }
}

export const account = (id) => {

  return {
    method: 'GET',
    url: `/api/contacts/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    },
  }
}


export const signup = (payload) => {

  const body = {
    entity: payload.entity,
    name: payload.name,
    firstname: payload.firstname,
    position: payload.position,
    job: payload.job,
    email: payload.email,
    phonenumber: payload.phonenumber
  }

  return {
    method: 'POST',
    url: `/api/register`,
    headers: {
      'Content-Type': 'application/json'
    },
    body
  }
}

export const forgotPassword = (payload) => {

  const body = {
    username: payload.username
  }

  return {
    method: 'POST',
    url: `/api/forgot_password`,
    headers: {
      'Content-Type': 'application/json',
    },
    body
  }
}

export const resetPassword = (payload) => {

  const body = {
    password: payload.password,
    oldPassword: payload.oldPassword
  }

  return {
    method: 'POST',
    url: `/api/reset_password`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    },
    body
  }
}

export const updateAccount = (payload) => {

  let body = new FormData()

  if (payload.avatar) {
    body.append(`file`, payload.avatar.file)
  }
  body.append('email', payload.email || '')
  body.append('job', payload.job || [])
  body.append('newPassword', payload.new_password || '')
  body.append('prefix', payload.prefix || '')

  return {
    method: 'POST',
    url: `/api/contact/update`,
    headers: {
      Authorization: true
    },
    body
  }
}

export const updateNotification = (payload) => {

  const body = {
    ...payload
  }

  return {
    method: 'POST',
    url: `/api/contact/update/notification`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    },
    body
  }
}
