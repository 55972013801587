import { ajax } from 'rxjs/ajax'
import {map, catchError} from 'rxjs/operators';
import { getPanels } from 'store/services/panel'
import { normalize } from 'normalizr';
import { panelSchema } from "store/schemas";
import { updateClient } from 'store/ducks/Client';
import merge from 'lodash/merge';

export const GET_PANEL_REQUEST = 'panel/GET_PANEL_REQUEST';
export const GET_PANEL_SUCCESS = 'panel/GET_PANEL_SUCCESS';
export const GET_PANEL_FAILURE = 'panel/GET_PANEL_FAILURE';
export const GET_PANEL_END = 'panel/GET_PANEL_END';

export const SELECT_PANEL = 'panel/SELECT_PANEL';
export const UPDATE_PANEL = 'panel/UPDATE_PANEL';


const INIT_STATE = {
  fetched: [],
  loading: false,
  selected: null,
  list: {}
};


export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_PANEL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UPDATE_PANEL:
    case GET_PANEL_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: merge({}, state.list, action.result.entities.panel),
      };
    case GET_PANEL_END:
      return {
        ...state,
        loading: false
      }
    case SELECT_PANEL:
      return {
        ...state,
        selected: action.result
      }
    default:
      return state;
  }
}


export function getPanelRequest(clientId) {
  return {
    types: [
      GET_PANEL_REQUEST,
      GET_PANEL_SUCCESS,
      GET_PANEL_FAILURE,
      GET_PANEL_END
    ],
    promise: (getState, dispatch) => ajax(
      getPanels(clientId)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member'];

        dispatch(updateClient({
          ...getState().client.list[clientId],
          panels: response,
        }));

        return {
          ...normalize(response, [panelSchema]),
          fetched: clientId,
        };
      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function updatePanel (panel) {
  return {
    type: UPDATE_PANEL,
    result: normalize(panel, panelSchema)
  }
}

export function selectPanel(panelId) {
  return {
    type: SELECT_PANEL,
    result: panelId
  }
}
