
export function getClientIncidents(id, search, filter, limit) {

  let url = `/api/clients/${id}/incidents?1=1`

  if(search) {
    url = url + `&search=${search}`
  }
  if(filter) {
    url = url + `&filter=${filter}`
  }
  if(limit) {
    url = url + `&limit=${limit}`
  }

  return {
    method: 'GET',
    url: url,
    headers: {
      Authorization: true
    },
  }
}

export function getIncident(id) {
  return {
    method: 'GET',
    url: `/api/incidents/${id}`,
    headers: {
      Authorization: true
    },
  }
}

export function getIncidentFiles(id) {
  return {
    method: 'GET',
    url: `/api/incidents/${id}/files`,
    headers: {
      Authorization: true
    },
  }
}

export function updateIncident(incidentId, payload) {

  return {
    method: 'PUT',
    url: `/api/incidents/${incidentId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    },
    body: payload
  }
}

export function closeIncident(incidentId) {

  return {
    method: 'POST',
    url: `/api/incidents/${incidentId}/close`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    }
  }
}

export function postIncidentAttachment(incidentId, file) {

  let body = new FormData()

  body.append(`files[]`, file)

  return {
    method: 'POST',
    url: `/api/incidents/${incidentId}/attachment`,
    headers: {
      Authorization: true
    },
    body
  }
}

export function postIncident(payload) {

  const body = {
    isMailContact: payload.answereRequested === 'email',
    isTelContact: payload.answereRequested === 'phone',
    isContactedMonday: payload.availability ? payload.availability.includes('monday') : false,
    isContactedTuesday: payload.availability ? payload.availability.includes('tuesday') : false,
    isContactedWednesday: payload.availability ? payload.availability.includes('wednesday') : false,
    isContactedThursday: payload.availability ? payload.availability.includes('thursday') : false,
    isContactedFriday: payload.availability ? payload.availability.includes('friday') : false,
    contactHourStartAt: payload.contactHourStartAt || null,
    contactHourEndAt: payload.contactHourEndAt || null,
    recallAt: payload.availableAt || null,
    clientProduct: payload.product || '',
    isBlocked: payload.blocking,
    email: payload.email || null,
    orgUnitLinkRecID: payload.entity,
    phone: payload.contactPhone || '',
    profileLinkRecID: payload.profileLinkRecID || null,
    subject: payload.subject || '',
    symptom: payload.description || '',
    isReportedByAlternateContact: payload.isReportedByAlternateContact || false,
    alternateContactLinkRecID: payload.alternateContactLinkRecID || null,
    incidentDelegationEndAt: null,
  }

  return {
    method: 'POST',
    url: `/api/incidents`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    },
    body
  }
}
