

export function getClientContact(id) {
  return {
    method: 'GET',
    url: `/api/clients/${id}/contacts`,
    headers: {
      Authorization: true
    },
  }
}

export function getContact(id) {
  return {
    method: 'GET',
    url: `/api/contacts/${id}`,
    headers: {
      Authorization: true
    },
  }
}

export function updateRoleOfContact(contactId, role, value) {

  const body = {
    id: contactId,
    [role]: value
  }

  return {
    method: 'PUT',
    url: `/api/contacts/${contactId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true
    },
    body
  }
}

export function updateContact(id, payload) {

  let body = new FormData()

  for (const [key, value] of Object.entries(payload)) {
    if (key === 'avatar' && value) {
      body.append(`file`, payload.avatar.file)
    } else {
      body.append(key, value)
    }
  }

  return {
    method: 'POST',
    url: `/api/contact/${id}/update`,
    headers: {
      Authorization: true
    },
    body
  }
}

export function disableContact(id) {

  return {
    method: 'POST',
    url: `/api/contact/${id}/disable`,
    headers: {
      Authorization: true
    }
  }
}

export function deleteContact(id) {

  return {
    method: 'POST',
    url: `/api/contact/${id}/delete`,
    headers: {
      Authorization: true
    }
  }
}
