
export function getClientToken(id) {
  return {
    method: 'GET',
    url: `/api/clients/${id}/tokens`,
    headers: {
      Authorization: true
    },
  }
}
