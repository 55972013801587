import { ajax } from 'rxjs/ajax'
import {map, catchError} from 'rxjs/operators'
import { getProductArticle } from 'store/services/article'
import { updateProduct } from 'store/ducks/Product'
import { updateClient } from 'store/ducks/Client'
import { normalize } from 'normalizr'
import { articleSchema } from "store/schemas"

export const GET_PRODUCT_ARTICLE_REQUEST = 'article/GET_PRODUCT_ARTICLE_REQUEST'
export const GET_PRODUCT_ARTICLE_SUCCESS = 'article/GET_PRODUCT_ARTICLE_SUCCESS'
export const GET_PRODUCT_ARTICLE_FAILURE = 'article/GET_PRODUCT_ARTICLE_FAILURE'
export const GET_PRODUCT_ARTICLE_END = 'article/GET_PRODUCT_ARTICLE_END'

const INIT_STATE = {
  fetched: [],
  loading: false,
  list: {},
  categories: {}
}

export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_PRODUCT_ARTICLE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_PRODUCT_ARTICLE_SUCCESS:
      return {
        ...state,
        fetched: action.result.fetched ? [...new Set([...state.fetched, action.result.fetched])] : state.fetched,
        list: {
          ...state.list,
          ...action.result.entities.article
        },
        categories: {
          ...state.categories,
          ...action.result.entities.catalogueCategory
        }
      }
    case GET_PRODUCT_ARTICLE_END:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}


export function getArticleOfProductRequest(productId) {
  return {
    types: [
      GET_PRODUCT_ARTICLE_REQUEST,
      GET_PRODUCT_ARTICLE_SUCCESS,
      GET_PRODUCT_ARTICLE_FAILURE,
      GET_PRODUCT_ARTICLE_END
    ],
    promise: (getState, dispatch) => ajax(
      getProductArticle(productId)
    ).pipe(
      map((res) => {
        const response = res.response['hydra:member']

        dispatch(updateProduct({
          ...getState().product.list[productId],
          articles: response,
          categories: [...new Set(
            [...response].reduce((acc, curr) => [...acc, curr.catalogueCategory.id], [])
              .map(Number))
          ]
        }))

        return {
          ...normalize(response, [articleSchema]),
          fetched: productId,
        };

      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}
