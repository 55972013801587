

export function getNotifications() {
  return {
    method: 'GET',
    url: `/api/notifications`,
    headers: {
      Authorization: true
    },
  }
}


export function readNotifications($ids) {

  const body = $ids;

  return {
    method: 'POST',
    url: `/api/notification/read`,
    headers: {
      Authorization: true,
      'Content-Type': 'application/json',
    },
    body
  }
}
