import { ajax } from 'rxjs/ajax'
import {map, takeUntil, catchError, mergeMap} from 'rxjs/operators';
import { timer } from 'rxjs';
import { getNotifications, readNotifications } from 'store/services/notification'
import { normalize } from 'normalizr';
import { notificationSchema } from "store/schemas";
import {combineEpics, ofType} from "redux-observable";

export const GET_NOTIFICATION_REQUEST = 'notification/GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'notification/GET_NOTIFICATION_SUCCESS';

export const UPDATE_NOTIFICATION = 'notification/UPDATE_NOTIFICATION';

export const READ_NOTIFICATIONS_REQUEST = 'notification/READ_NOTIFICATIONS_REQUEST';
export const READ_NOTIFICATIONS_SUCCESS = 'notification/READ_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATIONS_FAILURE = 'notification/READ_NOTIFICATIONS_FAILURE';
export const READ_NOTIFICATIONS_END = 'notification/READ_NOTIFICATIONS_END';


const POOLING_RATE = 60000;

const INIT_STATE = {
  fetched: false,
  list: {},
  loadingIds: []
};


export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case READ_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loadingIds: [
          ...state.loadingIds,
          ...action.context
        ]
      }
    case READ_NOTIFICATIONS_SUCCESS:
    case UPDATE_NOTIFICATION:
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: {
          ...state.list,
          ...action.result.entities.notification
        }
      }
    case READ_NOTIFICATIONS_END:
      return {
        ...state,
        loadingIds: state.loadingIds.filter((el) => !action.context.includes(el))
      }
    default:
      return state;
  }
}

export const getNotificationRequest = () => {
  return {
    type: GET_NOTIFICATION_REQUEST,
  }
}

export const getNotificationSuccess = (payload) => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    result: payload
  }
}

export const epic = combineEpics(
  getNotificationRequestEpic
)


export function getNotificationRequestEpic(action$) {
  return action$.pipe(
    ofType(GET_NOTIFICATION_REQUEST),
    mergeMap(() => timer(0, POOLING_RATE).pipe(
      mergeMap(() => ajax(getNotifications())),
      map((res) => {
        const response = res.response['hydra:member'];

        return getNotificationSuccess(normalize(response, [notificationSchema]));
      }),
      takeUntil(action$.pipe(ofType(GET_NOTIFICATION_REQUEST))),
    ))
  )
}


export function readNotificationsRequest(notificationIds) {
  return {
    types: [
      READ_NOTIFICATIONS_REQUEST,
      READ_NOTIFICATIONS_SUCCESS,
      READ_NOTIFICATIONS_FAILURE,
      READ_NOTIFICATIONS_END
    ],
    context: notificationIds,
    promise: (getState, dispatch) => ajax(
      readNotifications(notificationIds)
    ).pipe(
      map((res) => {

        const value = notificationIds.map((id) => ({
          ...getState().notification.list[id],
          isNotRead: false
        }));

        return normalize(value, [notificationSchema])

      }),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}


export function updateNotification (notification) {
  return {
    type: UPDATE_NOTIFICATION,
    result: normalize(notification, notificationSchema)
  }
}
