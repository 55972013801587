

const Colors = {
  black: '#000000',
  white: '#FFFFFF',
  gray0: "#8299A6",
  gray1: "#BBB9BB",
  gray2: "#EEEDEE",
  gray3: "#9B9B9B",
  gray4: "#4A4A4A",
  gray5: "#747374",
  grayTransparent: "#AFAFAF",
  blue0: "#00506B",
  blue1: "#0284AC",
  blue2: "#6EAFB8",
  pink0: "#985D8A",
  pink1: "#BD5170",
  pink2: "#EA85AA",
  orane: "#EF8001",
  green: "#87B440",
  brown: "#8A8275",
  red: "#D0021B",
  yellow: "#FDBF25"
}

export default Colors;
