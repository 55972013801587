export function getTaskExchanges (taskId) {
  return {
    method: 'GET',
    url: `/api/tasks/${taskId}/exchanges`,
    headers: {
      Authorization: true,
    },
  };
}

export function getIncidentExchanges (incidentId) {
  return {
    method: 'GET',
    url: `/api/incidents/${incidentId}/exchanges`,
    headers: {
      Authorization: true,
    },
  };
}

export function postIncidentNote (incidentId, {note, subject}) {

  const body = {
    body: note,
    subject: note.substring(0, 254)
  }

  return {
    method: 'POST',
    url: `/api/incidents/${incidentId}/note`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true,
    },
    body
  };
}

export function postTaskNote (taskId, {note, subject}) {

  const body = {
    body: note,
    subject: note.substring(0, 254)
  }

  return {
    method: 'POST',
    url: `/api/tasks/${taskId}/note`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: true,
    },
    body
  };
}
