import { ajax } from 'rxjs/ajax'
import {map, catchError} from 'rxjs/operators';
import { getClients } from 'store/services/client'
import { normalize } from 'normalizr';
import { clientSchema } from "store/schemas";
import merge from "lodash/merge";

export const GET_CLIENT_REQUEST = 'client/GET_CLIENT_REQUEST';
export const GET_CLIENT_SUCCESS = 'client/GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'client/GET_CLIENT_FAILURE';
export const GET_CLIENT_END = 'client/GET_CLIENT_END';

export const SELECT_CLIENT = 'client/SELECT_CLIENT';
export const UPDATE_CLIENT = 'client/UPDATE_CLIENT';


const INIT_STATE = {
  fetched: false,
  loading: false,
  selected: null,
  list: {}
};


export function reducer (state = INIT_STATE, action) {
  switch (action.type) {
    case GET_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UPDATE_CLIENT:
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        fetched: true,
        list: merge({}, state.list, action.result.entities.client),
      }
    case GET_CLIENT_END:
      return {
        ...state,
        loading: false
      }
    case SELECT_CLIENT:
      return {
        ...state,
        selected: action.result
      }
    default:
      return state;
  }
}


export function getClientRequest() {
  return {
    types: [
      GET_CLIENT_REQUEST,
      GET_CLIENT_SUCCESS,
      GET_CLIENT_FAILURE,
      GET_CLIENT_END
    ],
    promise: (getState) => ajax(
      getClients(getState().auth.clientId)
    ).pipe(
      map((res) => normalize(res.response, [clientSchema])),
      catchError((error) => Promise.reject(error)),
    ).toPromise()
  }
}

export function updateClient (client) {
  return {
    type: UPDATE_CLIENT,
    result: normalize(client, clientSchema)
  }
}

export function selectClient(clientId) {
  return {
    type: SELECT_CLIENT,
    result: clientId
  }
}
