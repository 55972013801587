import { connect } from 'react-redux';
import { switchLanguage, toggleCollapsedSideNav, toggleCartVisible } from "store/ducks/Setting";
import { getNotificationRequest, readNotificationsRequest } from "store/ducks/Notification";
import { sortedNotificationList } from "store/selectors/notification";

const mapStateToProps = (state, props) => ({
  notifications: sortedNotificationList(state),
  loadingIds: state.notification.loadingIds
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getNotification: () => dispatch(getNotificationRequest()),
  readNotifications: (ids) => dispatch(readNotificationsRequest(ids))
})

export default connect(mapStateToProps, mapDispatchToProps);
