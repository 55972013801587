import React from 'react';
import { Tag as TagAntd } from 'antd';

const Tag = ({
  children,
  className = '',
  color = '#7A7878',
  revert,
  ...props
}) => {
  if (revert) {
    return <TagAntd className={`arpec-tag ${className}`} color={'#FFFFFF'} {...props}><span style={{ color: color }}>{children}</span></TagAntd>;
  }
  return <TagAntd className={`arpec-tag ${className}`} color={color} {...props}>{children}</TagAntd>;
};

export default Tag;
