import { connect } from 'react-redux';
import { switchLanguage, toggleCollapsedSideNav, toggleCartVisible } from "store/ducks/Setting";
import { getClientRequest } from "store/ducks/Client";
import { hasUnReadNotification } from "store/selectors/notification";

const mapStateToProps = (state, props) => ({
    cartVisible: state.settings.cartVisible,
    locale: state.settings.locale,
    navStyle: state.settings.navStyle,
    navCollapsed: state.settings.navCollapsed,
    width: state.settings.width,
    clientId: state.auth.clientId,
    profile: state.client.list[state.auth.clientId],
    notificationFetched: state.notification.fetched,
    hasUnReadNotification: hasUnReadNotification(state),
    hasItemShoppingCart: Object.values(state.cart.list).length > 0
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getClient: () => dispatch(getClientRequest(ownProps.clientId)),
    toggleCartVisible: (isVisible) => dispatch(toggleCartVisible(isVisible)),
    toggleCollapsedSideNav: () => dispatch(toggleCollapsedSideNav(!ownProps.navCollapsed)),
    switchLanguage: () => dispatch(switchLanguage(!ownProps.navCollapsed)),
})

export default connect(mapStateToProps, mapDispatchToProps);
