const HOSTNAME = window.location.hostname;

const createParserFromUrl = (url) => {
  const parser = document.createElement('a');
  parser.href = url;
  return parser;
};

const getHost = (url) => {
  if (isAbsolute(url)) {
    const parser = createParserFromUrl(url);
    return parser.hostname;
  }
  return HOSTNAME;
};

const isAbsolute = (url) => {
  return /^https?:\/\//.test(url);
};

export const getRelativePath = (url) => {
  if (url.indexOf('#') !== -1) {
    const parser = createParserFromUrl(url);
    return parser.hash.replace('#', '');
  }
  return url;
};

export const isExternal = (url) => {
  return HOSTNAME !== getHost(url);
};
