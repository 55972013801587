import React, {memo} from 'react';
import { Input } from 'antd';
const { Search } = Input;

const InputSearch = ({size, placeholder, ...props}) => {
  return (
    <Search
      className="arpec-input-search"
      placeholder={placeholder}
      onSearch={value => console.log(value)}
      size={size}
      {...props}
      />
  )
}

InputSearch.defaultProps = {
  size: 'default',
  placeholder: 'Recherchez'
}

export default memo(InputSearch)


